import {
  ArithmeticAggregators,
  DataType,
  Entity,
  EntityPropertyValue,
  KindDescriptor,
  QuantileAggregators
} from "../../../../core";
import { PropertySource } from "../../entity-mapping";

export type PredicateOp = "eq" | "lt" | "le" | "gt" | "ge" | "ne" | "in";

export type StringOperation = "eq" | "contains" | "regex" | "startsWith" | "exists" | "doesNotExist";
export type NumberOperation = "lt" | "gt" | "neq" | "ge" | "le" | "in" | "range" | "eq";
export type EntityOperation = StringOperation | NumberOperation;

export type ExploreType = "bizEntity" | "userService";

export const EXPLORE_TYPE_BIZ_ENTITY: ExploreType = "bizEntity";
export const EXPLORE_TYPE__USERSERVICE: ExploreType = "userService";

export interface EntityField {
  entityType: string;
  relNames?: RelationshipName[];
  propType: DataType | "NA";
  propName: string;
  kindDescriptor?: KindDescriptor;
}

export interface BizField {
  entityField: EntityField;
}

export interface RelationshipName {
  relName: string;
  entityType: string;
  relId?: string;
}

export enum UserServiceFieldSource {
  NA = "NA",
  ENTITY = "ENTITY",
  EVENT = "EVENT"
}

export interface UserServiceField {
  fieldName: string;
  dataType: DataType;
  bizEntityFieldName: string;
  displayBizEntityFieldName: string;
  userServices: UserServiceTuple[];
  allUserService?: boolean;
  entityField?: EntityField;
  isAvailableInTenantContext?: boolean;

  fieldSource?: UserServiceFieldSource;
}

export enum AlertSummaryType {
  active = "active",
  all = "all"
}

export interface UserServiceTuple {
  userServiceEntityId: string;
  // root api is omited in event base field picker response
  rootApi?: string;
}

export interface UIUserService {
  userService: Entity;
  rootApis: Entity[];
}

export interface SliceSet {
  slices: Slice[];
}

export interface Slice {
  tagName: string;
  fieldName: string;
  fieldType: DataType;
  entityTypeName: string;
}

export type PostAgg =
  | OverTimePostAgg
  | OverTagPostAgg
  | HistogramPostAgg
  | (OverTagPostAgg & OverTimePostAgg)
  | (OverTagPostAgg & OverTimePostAgg & HistogramPostAgg);

export type PostAggProjection = "all" | "current" | "timeShift" | "deltaPercentage" | "bounds" | "delta" | "trends";

export type SortSpecSortBy = PostAggProjection;

export type LimitSpecFunction = "top" | "bottom";

export interface LimitSpec {
  limit: number;
  function: LimitSpecFunction;
}

export interface SortSpec {
  sortBy: SortSpecSortBy;
  limitSpec: LimitSpec;
}

export interface HistogramAgg {
  spec?: HistogramSpec;
  groupBys: string[];
}

export interface HistogramSpec {
  spec: NumberHistogramParamSpec;
}

export interface NumberHistogramParamSpec {
  min?: number;
  max?: number;
  bins: number[];
}

export interface FilterSpec {
  op: NumberOperation;
  value: EntityPropertyValue;
}

export enum ForecastProjection {
  projectionUnset = "projection_unset",
  forecast = "forecast",
  forecastLower = "forecast_lower",
  forecastUpper = "forecast_upper",
  forecastDelta = "forecast_delta",
  forecastDeltaPerc = "forecast_delta_perc",
  all = "all"
}

export interface ForecastSpec {
  projections: ForecastProjection[];
}

interface PostAggCommon {
  timeShiftCompareSeconds?: number;
  projections?: PostAggProjection[];
  sortSpec?: SortSpec;
  filters?: FilterSpec[];
  isSchemaQuery?: boolean;
  isSingleStatQuery?: boolean;
  forecastSpec?: ForecastSpec;
}

export interface OverTimePostAgg extends PostAggCommon {
  overTimeAgg?: OverTimeAgg;
}

export interface OverTagPostAgg extends PostAggCommon {
  overTagAgg: OverTagAgg;
}

export interface HistogramPostAgg extends PostAggCommon {
  histogramAgg: HistogramAgg;
}

// Remove | string in the following commit
export type OverTimeAggregators = ArithmeticAggregators | QuantileAggregators | string;
export type OverTagAggregators = ArithmeticAggregators | QuantileAggregators | string;

export interface OverTimeAgg {
  aggregator: OverTimeAggregators;
  timeInSeconds: number;
}

export interface OverTagAgg {
  aggregator: OverTagAggregators;
  tagName: string[];
}

export interface SelectorSpec {
  filters: SelectorFilter[];
}

export interface SelectorFilter {
  tags: SelectorTag[];
}

export interface SelectorTag {
  key: string;
  value: string[];
}

export interface SliceSpec {
  // oneOf
  metricId?: string;
  fieldId?: string;
  buildingBlockConfigId?: string;
  journeyNodeId?: string;

  sliceSet: SliceSet;
  selectorSpec: SelectorSpec;
  postAgg?: PostAgg;

  sliceSpecId?: string;
  timeWindowOverride?: TimeWindow;
}

export interface TimeWindow {
  relativeTime?: TimeObj;
  timeRange?: TimeWindowTimeRange;
}

export interface TimeWindowTimeRange {
  startTimeMillis: number;
  endTimeMillis: number;
}

export interface TimeObj {
  value: number;
  unit: TimeObjUnit;
}

export enum TimeObjUnit {
  unset = "timeunit_unset",
  millis = "milliseconds",
  seconds = "seconds",
  minutes = "minutes",
  hours = "hours",
  days = "days",
  weeks = "weeks",
  months = "months",
  years = "years"
}

export interface ColumnDef {
  columnName: string;
  tagName?: string;
  sliceSpecId?: string;
}

export interface ColumnDefList {
  columnDefs?: ColumnDef[];
}

export interface SheetDef {
  sheetName: string;
  columns?: ColumnDefList;
  sortSliceSpecId?: string; // Will be used to override DataSortSpec in WidgetAdhocDataRequestV2
}

export interface WorkBookDef {
  sheets: SheetDef[];
  fileName: string;
}

export type MetricDefLabels = Partial<
  {
    isAggMetric: string; // stringified boolean
    systemCreated: string; // stringified boolean
    excludeDataFetch: string; // stringified boolean
    parentMetricId: string;
    generateCohortMetrics: string; // stringified boolean
    isAllEventTypesCase: string; // stringified boolean
  } & Record<string, string>
>;

export interface WidgetQuerySchema {
  metricId: string;
  fieldId?: string;
  sliceSet: SliceSet;

  resultKey: string;
  metricName: string;

  subType?: boolean;
  isSpikePositive?: boolean;

  defaultAgg?: OverTagAggregators;
  defaultTagAgg: OverTagAggregators;
  defaultTimeAgg: OverTimeAggregators;

  bizField?: BizField;
  sourceUserServiceField?: UserServiceField;
  componentSourceFields?: Record<string, UserServiceField>;
  baseEventNavigation?: BaseEventNavigation;
  componentsEventNavigation?: Record<string, BaseEventNavigation>;

  labels?: MetricDefLabels;
}

export interface BaseEventNavigation {
  eventFilter?: string;
}

export interface WidgetQuerySchemaResponse {
  querySchema: WidgetQuerySchema[];
}

export interface BizFieldWithMetaList {
  bizFields: BizFieldWithMeta[];
}

export interface BizFieldWithMeta {
  bizField: BizField;
  metadata: BizFieldMetadata;
}

export interface BizFieldMetadata {
  bizFieldSources: BizFieldSource[];
  writable: boolean;
  bizFieldDestinations: BizFieldSource[];
}

export interface BizFieldSource {
  sourceType: string;
  sourceName: string;
  source: PropertySource;
}

export enum ResponseStatus {
  FAILED = "FAILED",
  ACK = "ACK",
  SUCCESS = "SUCCESS"
}

export type ErrorInfo = {
  errorCode: string;
  message: string;
};

export type ResponseInfo = {
  status: ResponseStatus;
  errors: ErrorInfo[];
  ignoredProps: Record<string, string>;
};

export enum OverallMappingStatus {
  NA = "na",
  PENDING = "pending",
  COMPLETE = "complete"
}

export interface QueryMappingStatus {
  usFields: USFieldMappingStatus[];
  entityFields: EntityFieldMappingStatus[];

  // oneOf
  overallStatus?: OverallMappingStatus;
  isIncomplete?: boolean;
}

export interface Op10zeMappingStatus {
  queryMappingStatus: QueryMappingStatus;
  actionMappingStatus: ActionMappingStatus;
}

interface ActionMappingStatus {
  actionId: string;
  status: MappingStatus;
}

interface USFieldMappingStatus {
  field: UserServiceField;
  status: MappingStatus;
}

interface EntityFieldMappingStatus {
  field: EntityField;
  status: MappingStatus;
}

interface MappingStatus {
  status: MappingStatusInfo;
  message: string;
}

enum MappingStatusInfo {
  NOT_DEFINED = "not_defined",
  HEALTHY = "healthy",
  UNHEALTHY = "unhealthy",
  INCOMPLETE = "incomplete"
}
