import { sortBy } from "lodash";
import appConfig from "../../../../appConfig";
import { logger } from "../../../core";
import { entityEnricherRegistry, getPickerUrlPrefix, getUserServiceUrlPrefix } from "../../../utils";
import { request } from "../base-api";
import { FieldAggregationResponse } from "../types";
import { BizService } from "./BizServiceCommon";
import {
  BizEntityType,
  BizEntityTypeList,
  BizEntityUserServiceList,
  BizField,
  BizFieldPredicate,
  BizJourney,
  BizJourneyListResponse,
  BizJourneyPath,
  BizJourneyPathPayload,
  BizJourneyPathResponse,
  BizJourneyPathsResponse,
  DemoDataParams,
  EntityNamesRequest,
  EntityNamesResponse,
  ExploreUserService,
  ExploreUserServicesList,
  FieldPickerContext,
  FieldSchemaResponse,
  FieldValues,
  FieldValuesInput,
  GenericAPIResult,
  JourneyFieldsRequest,
  JourneyFieldsResponse,
  JourneyListResponse,
  UserServiceField,
  UserServiceInfoList
} from "./types";

class FieldPickerApiService extends BizService {
  async getBizEntityTypes(): Promise<GenericAPIResult<BizEntityType[]>> {
    this.init();
    const url = this.getBizEntityUrl("list");
    const result: GenericAPIResult<BizEntityType[]> = {
      data: [],
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<BizEntityTypeList, unknown>(url);
      let bizEntityTypes = [...response.data.resultEntityTypes];
      bizEntityTypes = sortBy(bizEntityTypes, et => et.name);
      result.data.push(...bizEntityTypes);
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getJourneysList(): Promise<GenericAPIResult<BizJourney[]>> {
    this.init();
    const url = this.getBizEntityUrl(`bizJourney`);
    const result: GenericAPIResult<BizJourney[]> = {
      data: [],
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<BizJourneyListResponse, unknown>(url);
      result.data = response.data.bizJourney;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getJourneysListV2(
    companyName: string,
    generateDemoData: boolean
  ): Promise<GenericAPIResult<JourneyListResponse>> {
    this.init();

    const url = this.getBizEntityUrl(`journeys/v2/list`);

    const result: GenericAPIResult<JourneyListResponse> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<JourneyListResponse, unknown>(
        url,
        {},
        {
          params: {
            companyName,
            generateDemoData
          }
        }
      );
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  async getJourneyFields(journeyIds: string[], companyName: string): Promise<GenericAPIResult<JourneyFieldsResponse>> {
    this.init();

    const url = this.getBizEntityUrl(`journeys/v2/fields?companyName=${companyName}&generateDemoData=true`);
    const result: GenericAPIResult<JourneyFieldsResponse> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.post<JourneyFieldsResponse, JourneyFieldsRequest>(url, {
        journeyIds
      });
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  async getJourneyPaths(
    id: string,
    entityFilters: BizFieldPredicate[],
    startTimeMillis: number,
    endTimeMillis: number,
    compareSeconds: number
  ): Promise<GenericAPIResult<BizJourneyPathsResponse>> {
    this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const url = this.getBizEntityUrl(`bizJourney/${id}/paths${timeParams}`);

    const result: GenericAPIResult<BizJourneyPathsResponse> = {
      data: null,
      error: false,
      message: ""
    };

    const payload: BizJourneyPathPayload = {
      entityFilters: [
        {
          filters: entityFilters
        }
      ],
      compareTimeInSeconds: compareSeconds
    };

    try {
      const response = await this.datasource.post<BizJourneyPathsResponse, BizJourneyPathPayload>(url, payload);
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getJourneyPath(
    id: string,
    entityFilters: BizFieldPredicate[],
    startTimeMillis: number,
    endTimeMillis: number
  ): Promise<GenericAPIResult<BizJourneyPath>> {
    this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const url = this.getBizEntityUrl(`bizJourney/${id}/path${timeParams}`);

    const result: GenericAPIResult<BizJourneyPath> = {
      data: null,
      error: false,
      message: ""
    };

    const payload = {
      entityFilters: [
        {
          filters: entityFilters
        }
      ]
    };

    try {
      const response = await this.datasource.post<BizJourneyPathResponse, unknown>(url, payload);
      result.data = response.data.path;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getBizEntityType(bizEntityTypeId?: string, generateDemoData = false): Promise<GenericAPIResult<BizEntityType>> {
    this.init();
    const url = this.getBizEntityUrl(bizEntityTypeId);
    const result: GenericAPIResult<BizEntityType> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const { data } = await this.datasource.get<BizEntityType, unknown>(url, null, { params: { generateDemoData } });
      result.data = data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getUserservicesList(
    startTimeMillis: number,
    endTimeMillis: number,
    demoDataParams?: DemoDataParams
  ): Promise<GenericAPIResult<ExploreUserService[]>> {
    this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const url = this.getUserServiceUrl(`list${timeParams}`);
    const result: GenericAPIResult<ExploreUserService[]> = {
      data: [],
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<ExploreUserServicesList, unknown>(url, null, {
        params: demoDataParams || {}
      });
      let exploreUserservices = [...response.data.resultEntities];
      exploreUserservices = sortBy(exploreUserservices, et => et.name);
      result.data.push(...exploreUserservices);
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getUserserviceInfo(
    userServiceId: string,
    startTimeMillis: number,
    endTimeMillis: number,
    generateDemoData = false
  ): Promise<GenericAPIResult<ExploreUserService>> {
    this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const subUrl = userServiceId + timeParams;
    const url = this.getUserServiceUrl(subUrl);
    const result: GenericAPIResult<ExploreUserService> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<ExploreUserService, unknown>(url, null, {
        params: { generateDemoData }
      });
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getBizEntityUserserviceList(
    entityTypeId: string,
    startTimeMillis: number,
    endTimeMillis: number,
    includeSystemFieldInfo: boolean
  ) {
    this.init();
    const subUrl = `${entityTypeId}/schema/userService`;
    const url = this.getBizEntityUrl(subUrl);
    const result: GenericAPIResult<BizEntityUserServiceList> = {
      data: null,
      error: false,
      message: ""
    };

    const params = {
      includeSystemFieldInfo,
      startTimeMillis,
      endTimeMillis
    };

    try {
      const response = await this.datasource.get<BizEntityUserServiceList, unknown>(url, null, { params });
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  /**
   *
   * @param entityType Entity Type example i_merchant, i_sfAccount etc
   * @param pickerContext FieldSchemaContext
   *  example {
   *    startTimeMillis in millis
   *    endTimeMillis in millis
   *    showMetrics boolean
   *    showFields boolean
   *    ...etc.
   * }
   * @param startTimeMillis
   * @param endTimeMillis
   * @param isJourney
   * @param demoParams
   */
  async getBizEntityFields(
    entityType: string,
    pickerContext: FieldPickerContext,
    startTimeMillis: number,
    endTimeMillis: number,
    isJourney = false,
    demoParams: DemoDataParams = null
  ): Promise<FieldSchemaResponse> {
    const shareId = appConfig.anomShareId;
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const subUrl = shareId ? `${timeParams}&shareId=${shareId}` : timeParams;
    const url = this.getFieldsUrl(null, entityType, subUrl, isJourney);
    const processedPickerContext = this.getProcessedPickerContext(pickerContext);

    const params: Record<string, any> = {
      ...(demoParams || {})
    };

    if (shareId) {
      const apiUrl = `${appConfig.apiDomainUrl}${url}`;
      const response = await request.post<FieldSchemaResponse, FieldPickerContext>(apiUrl, processedPickerContext, {
        params
      });
      return response.data;
    } else {
      this.init();

      const response = await this.datasource.post<FieldSchemaResponse, FieldPickerContext>(
        url,
        processedPickerContext,
        { params }
      );
      return response.data;
    }
  }

  async getAllUserServiceFields(startTimeMillis: number, endTimeMillis: number): Promise<FieldSchemaResponse> {
    const shareId = appConfig.anomShareId;
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    let subUrl = shareId ? `${timeParams}&shareId=${shareId}` : timeParams;
    subUrl = subUrl.startsWith("?") ? subUrl : `?${subUrl}`;

    const url = this.getUserServiceUrl(`/filters/fields${subUrl}`);

    if (shareId) {
      const apiUrl = `${appConfig.apiDomainUrl}${url}`;
      const response = await request.get<FieldSchemaResponse>(apiUrl);
      return response.data;
    } else {
      this.init();
      const response = await this.datasource.get<FieldSchemaResponse>(url);
      return response.data;
    }
  }

  async getBizEntityFilterFields(
    entityType: string,
    startTimeMillis: number,
    endTimeMillis: number
  ): Promise<FieldSchemaResponse> {
    this.init();
    const shareId = appConfig.anomShareId;

    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const subUrl = shareId ? `${timeParams}&shareId=${shareId}` : timeParams;
    const url = `/api/bizEntity/${entityType}/filters/fields${subUrl}`;

    if (shareId) {
      const apiUrl = `${appConfig.apiDomainUrl}${url}`;
      const response = await request.get<FieldSchemaResponse>(apiUrl);
      return response.data;
    } else {
      const response = await this.datasource.get<FieldSchemaResponse, unknown>(url);
      return response.data;
    }
  }

  async getUserserviceEntityField(
    userserviceEntityId: string,
    pickerContext: FieldPickerContext,
    startTimeMillis: number,
    endTimeMillis: number,
    demoParams: DemoDataParams = null
  ): Promise<FieldSchemaResponse> {
    this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const url = this.getFieldsUrl(userserviceEntityId, null, timeParams);
    const processedPickerContext = this.getProcessedPickerContext(pickerContext);

    const context = {
      ...processedPickerContext,
      startTimeMillis,
      endTimeMillis
    };

    delete context.bizEntityType;

    const params: Record<string, any> = {
      ...(demoParams || {})
    };

    const body: FieldPickerContext = context;
    const response = await this.datasource.post<FieldSchemaResponse, FieldPickerContext>(url, body, { params });
    return response.data;
  }

  async getFieldValueCompletion(
    payload: FieldValuesInput,
    startTimeMillis: number,
    endTimeMillis: number,
    searchText = "",
    limit = 1000,
    useFilterFieldValuesUrl = false
  ) {
    this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);

    const shareId = appConfig.anomShareId;
    let url = "";

    if (useFilterFieldValuesUrl) {
      const userServiceId = payload?.userServiceField?.userServices?.[0]?.userServiceEntityId || "";
      url = `${this.getUserServiceUrl()}${userServiceId}/filters/fields/values${timeParams}&filterText=${searchText}&limit=${limit}`;
    } else {
      url = `${getPickerUrlPrefix()}/values${timeParams}&fieldFilter=${searchText}&limit=${limit}`;
    }
    url += shareId ? `&shareId=${shareId}` : "";

    let response = null;
    if (shareId) {
      const newUrl = `${appConfig.apiDomainUrl}${url}`;
      response = await request.post<FieldValues, FieldValuesInput>(newUrl, payload);
    } else {
      response = await this.datasource.post<FieldValues, FieldValuesInput>(url, payload);
    }
    const data: FieldValues = response?.data as FieldValues;
    entityEnricherRegistry.addToEntityToCache(data.entityLookupData || {});
    return data;
  }

  async getEntityFieldValueCompletion(
    payload: BizField,
    startTimeMillis: number,
    endTimeMillis: number,
    searchText = "",
    cohortId = "",
    limit = 1000
  ) {
    const { entityType, propName } = payload?.entityField || {};
    this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    let url = `${this.getBizEntityUrl()}${entityType}/filters/fields/${propName}/values${timeParams}&filterText=${searchText}&limit=${limit}`;
    const shareId = appConfig.anomShareId;

    url += cohortId ? `&cohortId=${cohortId}` : "";
    url += shareId ? `&shareId=${shareId}` : "";

    let response = null;

    try {
      if (shareId) {
        const newUrl = `${appConfig.apiDomainUrl}${url}`;
        response = await request.post<FieldValues, BizField>(newUrl, payload);
      } else {
        response = await this.datasource.post<FieldValues, BizField>(url, payload);
      }
      return response.data as FieldValues;
    } catch (e) {
      logger.debug("FieldPickerApiService", "failed to fetch entity field value completion", e);
      return null;
    }
  }

  async getEntityLookup(entityIds: string[]) {
    this.init();

    const payload: EntityNamesRequest = {
      entityId: entityIds
    };
    const result: GenericAPIResult<EntityNamesResponse> = {
      data: null,
      error: false,
      message: ""
    };
    const shareId = appConfig.anomShareId;
    let url = `${getUserServiceUrlPrefix()}/filters/entity/names`;
    url += shareId ? `?shareId=${shareId}` : "";

    try {
      let response = null;
      if (shareId) {
        const newUrl = `${appConfig.apiDomainUrl}${url}`;
        response = await request.post<EntityNamesResponse, EntityNamesRequest>(newUrl, payload);
      } else {
        response = await this.datasource.post<EntityNamesResponse, EntityNamesRequest>(url, payload);
      }
      if (response.data) {
        result.data = response.data;
      }
    } catch (e) {
      result.error = true;
      result.message = e.message;
      logger.debug("FieldPickerApiService", "failed to fetch entity lookup", e);
    }
    return result;
  }

  async getFieldAggregations(fields: UserServiceField[]): Promise<GenericAPIResult<FieldAggregationResponse>> {
    this.init();
    const shareId = appConfig.anomShareId;
    let url = this.getUserServiceUrl("filters/fields/aggregations");
    url += shareId ? `&shareId=${shareId}` : "";

    const payload = {
      fields: fields
    };

    const result: GenericAPIResult<FieldAggregationResponse> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      let response = null;
      if (shareId) {
        const newUrl = `${appConfig.apiDomainUrl}${url}`;
        response = await request.post<FieldAggregationResponse, typeof payload>(newUrl, payload);
      } else {
        response = await this.datasource.post<FieldAggregationResponse, typeof payload>(url, payload);
      }
      if (response.data) {
        result.data = response.data;
      }
    } catch (e) {
      result.error = true;
      result.message = e.message;
      logger.debug("FieldPickerApiService", "failed to fetch field aggregations", e);
    }

    return result;
  }

  async getUserServiceListByUseCaseId(useCaseId: string, companyName: string) {
    this.init();
    const url = this.getBizEntityUrl(
      `useCase/v2/userService${useCaseId ? `?useCaseId=${useCaseId}&companyName=${companyName}` : `?companyName=${companyName}`}`
    );

    const result: GenericAPIResult<UserServiceInfoList> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<UserServiceInfoList>(url);

      if (response.data) {
        result.data = response.data;
      }
    } catch (e) {
      result.error = true;
      result.message = e.message;
      logger.debug("FieldPickerApiService", "failed to fetch user service list", e);
    }

    return result;
  }

  private getProcessedPickerContext(context: FieldPickerContext) {
    const processedPickerContext = { ...(context || {}) };
    if (processedPickerContext.userServices) {
      processedPickerContext.userServices = processedPickerContext.userServices.filter(usEntry =>
        Boolean(usEntry.userServiceEntityId)
      );
    }
    return processedPickerContext;
  }
}

const fieldPickerApiService = new FieldPickerApiService();

export default fieldPickerApiService;
