export enum RelativeDurationType {
  SECONDS,
  MINUTES,
  HOURS,
  DAYS,
  WEEKS,
  MONTHS
}

export enum RelativeDurationSuffix {
  SECONDS = "s",
  MINUTES = "m",
  HOURS = "h",
  DAYS = "d",
  WEEKS = "w",
  MONTHS = "M"
}

export interface IncDuration {
  durationType: RelativeDurationType;
  duration: number;
}
