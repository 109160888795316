import { MicroSiteTemplate } from "./template";

export enum ApiStatus {
  PENDING = "pending",
  COMPLETED = "completed",
  FAILED = "failed"
}

export type CreateMicroSiteResponse = {
  pollId: string;
  status: ApiStatus;
  errors: string[];
  companyName: string;
};

export type PollCreateMicroSiteResponse = {
  companyName: string;
  status: ApiStatus;
  progressPercentage: number;
  pollId: string;
  errors: string[];
  template: MicroSiteTemplate;
  suggestedDomains: string[];
};

// export type GetMicroSiteTemplateResponse = {
//   template: MicroSiteTemplate;
//   status: ApiStatus;
//   errors: string[];
// };

export type APIResponse<T> = {
  data: T;
  error: boolean;
  message: string;
};
