import React, { FC, useCallback, useRef, useMemo, ReactNode, useState, ReactElement, CSSProperties } from "react";
import { IncClickAwayPopper, IncPopperProps } from "@inception/ui";
import { generateId } from "../core";

interface Props {
  moreDetailsComponent: ReactElement;
  children: ReactNode[]; // Don't pass all entries as ReactNodes, pass only the ones you want to display
  maxEntries: number;
  delimiter?: string;
  totalEntries?: number; // Pass total Count of entries here
  skipMoreInMoreStr?: boolean;
  style?: CSSProperties;
  placement?: IncPopperProps["placement"];
  className?: string;
}

export const RenderWithMorePopper: FC<Props> = props => {
  const {
    children,
    maxEntries,
    totalEntries,
    moreDetailsComponent,
    delimiter = "",
    placement,
    className = "",
    skipMoreInMoreStr = false,
    style
  } = props;

  const [show, setShow] = useState(false);
  const viewMoreRef = useRef<HTMLDivElement>();

  const toggleShowMore = useCallback(() => setShow(prev => !prev), []);
  const closeShowMore = useCallback(() => setShow(false), []);

  const displayEntries = useMemo(() => {
    const displayEntries = children.slice(0, maxEntries);
    const numDisplayEntries = displayEntries.length;

    return displayEntries.map((child, idx) => {
      const addDelimiter = idx < numDisplayEntries - 1;
      return (
        <div
          className="inc-flex-row"
          key={generateId()}
        >
          {child}
          {addDelimiter && <div style={{ margin: "0px 2px 2px 2px" }}>{delimiter}</div>}
        </div>
      );
    });
  }, [children, delimiter, maxEntries]);

  const diff = totalEntries ? totalEntries - maxEntries : children.length - maxEntries;
  const displayMoreMessage = diff && skipMoreInMoreStr ? `+${diff}` : diff ? `+${diff} more` : "";

  return (
    <div
      className={`inc-flex-row inc-flex-row-wrap ${className}`}
      style={style}
    >
      {displayEntries}
      {diff > 0 && (
        <div
          className="marginLt4 inc-cursor-pointer inc-tag inc-tag-nonSelected"
          onClick={toggleShowMore}
          ref={viewMoreRef}
        >
          {displayMoreMessage}
        </div>
      )}
      <IncClickAwayPopper
        anchorEl={viewMoreRef.current}
        offset={offset}
        onClickAway={closeShowMore}
        overlay
        placement={placement}
        show={show}
      >
        {moreDetailsComponent}
      </IncClickAwayPopper>
    </div>
  );
};

const offset = {
  x: 5,
  y: 5
};
