import { CSVFilterConditionType } from "./CsvReader";

export const conditionLabelMap: Record<CSVFilterConditionType, string> = {
  array_contains: "contains",
  contains: "contains",
  ends_with: "ends with",
  eq: "=",
  gt: ">",
  gte: ">=",
  in: "falls in",
  json_path_match: "matches",
  lt: "<",
  lte: "<=",
  neq: "!=",
  nin: "not falls in",
  starts_with: "starts with"
};
