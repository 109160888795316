import React from "react";
import { IncDateTime, IncDateTimeFormat } from "@inception/ui";
import { TimeObj, TimeObjUnit } from "../services/api/explore";
import { dateTime } from "../core";

function DateTimeRenderer(props: { timeInMicrosec: number }) {
  const millis = Math.floor(props.timeInMicrosec / 1000);

  return (
    <IncDateTime
      format={IncDateTimeFormat.minimal}
      options={{
        withMilliSeconds: true,
        withSeconds: true
      }}
      value={millis}
    />
  );
}

export function getTimeObjFromMillis(value: number, unit: TimeObjUnit): TimeObj {
  switch (unit) {
    case TimeObjUnit.millis:
      return {
        unit,
        value
      };
    case TimeObjUnit.seconds:
      return {
        value: parseInt(String(value / 1000), 10),
        unit
      };
    case TimeObjUnit.minutes:
      return {
        value: parseInt(String(value / (60 * 1000)), 10),
        unit
      };
    case TimeObjUnit.hours:
      return {
        value: parseInt(String(value / (60 * 60 * 1000)), 10),
        unit
      };
    case TimeObjUnit.days:
      return {
        value: parseInt(String(value / (24 * 60 * 60 * 1000)), 10),
        unit
      };
    case TimeObjUnit.weeks:
      return {
        value: parseInt(String(value / (7 * 24 * 60 * 60 * 1000)), 10),
        unit
      };
    case TimeObjUnit.months:
      return {
        value: parseInt(String(value / (30 * 24 * 60 * 60 * 1000)), 10),
        unit
      };
    case TimeObjUnit.years:
      return {
        value: parseInt(String(value / (365 * 24 * 60 * 60 * 1000)), 10),
        unit
      };
    default:
      return {
        value,
        unit
      };
  }
}
export function getMillisFromTimeObj(timeObj: TimeObj): number {
  const { value, unit } = timeObj;
  switch (unit) {
    case TimeObjUnit.millis:
      return value;
    case TimeObjUnit.seconds:
      return value * 1000;
    case TimeObjUnit.minutes:
      return value * 60 * 1000;
    case TimeObjUnit.hours:
      return value * 60 * 60 * 1000;
    case TimeObjUnit.days:
      return value * 24 * 60 * 60 * 1000;
    case TimeObjUnit.weeks:
      return value * 7 * 24 * 60 * 60 * 1000;
    case TimeObjUnit.months:
      return value * 30 * 24 * 60 * 60 * 1000;
    case TimeObjUnit.years:
      return value * 365 * 24 * 60 * 60 * 1000;
    default:
      return value;
  }
}

export const getTimeRangeWithBuffer = (
  startTimeMillis: number,
  endTimeMillis: number,
  frequencyMillis?: number,
  ignoreEndBuffer?: boolean
) => {
  // Define constants
  const h12Buffer = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
  const minDataPoints = 12; // We want at least 12 data points
  const now = dateTime().seconds(0).milliseconds(0).valueOf();

  // For low-frequency data (6-hour intervals or longer)
  if (frequencyMillis && frequencyMillis >= 21600000) {
    // Calculate buffer to ensure we have at least 12 data points
    const pointsInRange = Math.ceil((endTimeMillis - startTimeMillis) / frequencyMillis);
    const additionalPointsNeeded = Math.max(0, minDataPoints - pointsInRange);

    // Calculate buffer based on the number of additional points needed
    const bufferRange = additionalPointsNeeded > 0 ? frequencyMillis * additionalPointsNeeded : frequencyMillis * 6; // Default buffer is 6 points worth

    const startTimeMillisWithBuffer = startTimeMillis - bufferRange;
    let endTimeMillisWithBuffer = endTimeMillis + (ignoreEndBuffer ? 0 : bufferRange);

    // Ensure endBuffer does not cross current time
    if (endTimeMillisWithBuffer > now) {
      endTimeMillisWithBuffer = now;
    }

    return {
      startTimeMillisWithBuffer,
      endTimeMillisWithBuffer: ignoreEndBuffer ? endTimeMillis : endTimeMillisWithBuffer
    };
  }

  // For higher-frequency data, ensure we have 12 hours worth
  const timeSpan = endTimeMillis - startTimeMillis;

  // If the current time span is less than 12 hours, extend it to 12 hours
  let startBuffer = timeSpan < h12Buffer ? (h12Buffer - timeSpan) / 2 : h12Buffer / 2;
  let endBuffer = timeSpan < h12Buffer ? (h12Buffer - timeSpan) / 2 : Math.min(now - endTimeMillis, h12Buffer / 2);

  // Ensure we have at least 12 data points if frequency is known
  if (frequencyMillis) {
    const pointsInTimespan = Math.ceil(timeSpan / frequencyMillis);
    if (pointsInTimespan < minDataPoints) {
      const additionalTimeNeeded = (minDataPoints - pointsInTimespan) * frequencyMillis;
      // Distribute the additional time between start and end
      startBuffer = Math.max(startBuffer, additionalTimeNeeded / 2);
      endBuffer = ignoreEndBuffer ? 0 : Math.max(endBuffer, additionalTimeNeeded / 2);
    }
  }

  const from = dateTime(startTimeMillis).subtract(startBuffer, "milliseconds");
  const to = dateTime(endTimeMillis).add(ignoreEndBuffer ? 0 : endBuffer, "milliseconds");

  return {
    startTimeMillisWithBuffer: from.valueOf(),
    endTimeMillisWithBuffer: ignoreEndBuffer ? endTimeMillis : to.valueOf()
  };
};

export default DateTimeRenderer;
