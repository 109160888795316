import { AxiosError } from "axios";
import { ApptuitDatasource } from "../../datasources/apptuit/ApptuitDatasource";
import datasourceApiManager from "../DatasourceApiService";
import { InceptionResponse } from "../types";
import { getBizEntityUrlPrefix } from "../../../utils";
import { CreateMicroSiteResponse, GenericAPIResult, PollCreateMicroSiteResponse } from "./types";

class MicrositeApiService {
  protected datasource: ApptuitDatasource;

  createCompanyMicrosite(email: string, prompt: string) {
    const fetchFn = () =>
      this.datasource.post<CreateMicroSiteResponse, any>(this.getUrl("/create"), {
        email,
        prompt
      });

    return this.call(fetchFn);
  }

  getCreateCompanyMicrositeStatus(pollId: string) {
    const fetchFn = () => this.datasource.get<PollCreateMicroSiteResponse>(this.getUrl(`/status/${pollId}`));

    return this.call(fetchFn);
  }

  protected getUrl(url = ""): string {
    if (url.startsWith("/")) {
      url = url.substring(1);
    }
    return `${getBizEntityUrlPrefix()}/microsite/${url}`;
  }

  protected init() {
    this.datasource = datasourceApiManager.getDefault();
  }

  private isErrorResponse(statusCode: number) {
    return statusCode >= 400 && statusCode < 500;
  }

  private async call<R>(promiseFn: () => Promise<InceptionResponse<R>>) {
    this.init();

    const response: GenericAPIResult<R> = {
      data: null as R,
      error: false,
      message: ""
    };

    try {
      const { data, status, statusText } = await promiseFn();
      response.data = data;
      if (this.isErrorResponse(status)) {
        response.error = true;
        response.message = statusText;
      } else {
        response.data = data;
      }
    } catch (err: any) {
      let message = err?.message || err?.data?.message || String(err);
      if ((err as AxiosError).isAxiosError) {
        message = err.response?.data?.message || err?.response?.statusText || err.message || String(err);
      }

      response.error = true;
      response.message = message;
    }

    return response;
  }
}

export const micrositeApiService = new MicrositeApiService();
