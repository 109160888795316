import { cloneDeep } from "lodash";
import {
  BizDataQuery,
  BizField,
  EntityMetricConfigDefinition,
  JourneyStepTimeQueryConfig,
  OverallMappingStatus,
  OverTagPostAgg,
  Slice,
  SliceSpec,
  UserServiceField,
  UserServiceFieldMetricConfigDefinition,
  UserServiceFieldSliceSet,
  UserServiceFieldStateToMonitor,
  WidgetQuerySchema,
  WidgetQuerySchemaResponse
} from "../types";
import exploreApiService from "../ExploreApiService";
import { generateId, logger } from "../../../../core";
import { getDtoFromWidgetConfig, getWidgetConfigFromDto } from "../../../../utils/ExploreUtils";
import { KPI } from "../../use-case-catalog";
import {
  extractMetricIdFromExpressionMetricConfig,
  getMetricIdsForExpressionMetric,
  getMetricNodesForExpressionMetric,
  WidgetConfigUtils
} from "./widgetConfigUtils";

export class BizDataQueryUtils {
  static modifyUSFSliceSetForBizDataQuery(bizDataQuery: BizDataQuery, usfSliceSet: UserServiceFieldSliceSet) {
    if (bizDataQuery) {
      const { widgetConfig, buildingBlockConfig, sliceSpec } = bizDataQuery;
      const sliceSet = WidgetConfigUtils.convertUSFieldSliceSetToTagSlice(usfSliceSet);

      let shouldModifySliceSpec = false;
      if (buildingBlockConfig && buildingBlockConfig.buildingBlockDef) {
        buildingBlockConfig.buildingBlockDef.sliceDef = {
          sliceSets: [usfSliceSet]
        };
        shouldModifySliceSpec = true;
      } else if (widgetConfig && sliceSpec?.metricId) {
        const metricToModify = widgetConfig.dataDefinition.metrics[sliceSpec.metricId];
        if (metricToModify) {
          if (metricToModify.sourceType === "userServiceField") {
            metricToModify.userServiceFieldMetricConfig.sliceSets = [usfSliceSet];
          } else if (metricToModify.sourceType === "expression") {
            const sliceSpecsToModify = getMetricNodesForExpressionMetric(metricToModify.expressionMetricConfig);
            sliceSpecsToModify.forEach(sliceSpec => {
              sliceSpec.sliceSet = sliceSet;

              const childMetric = widgetConfig.dataDefinition.metrics[sliceSpec.metricId];
              if (childMetric?.sourceType === "userServiceField") {
                childMetric.userServiceFieldMetricConfig.sliceSets = [usfSliceSet];
              }
            });
          }
          shouldModifySliceSpec = true;
        }
      }

      if (shouldModifySliceSpec) {
        sliceSpec.sliceSet = sliceSet;
      }
    }
  }

  static modifyUSFSliceSetForBizDataQueryByTag(bizDataQuery: BizDataQuery, tagNames: string[]) {
    if (!bizDataQuery) {
      return;
    }

    const { widgetConfig, buildingBlockConfig, sliceSpec } = bizDataQuery;
    let shouldModifySliceSpec = false;

    // Handle buildingBlockConfig case
    if (buildingBlockConfig?.buildingBlockDef) {
      const { sliceSets } = buildingBlockConfig.buildingBlockDef.sliceDef || {};
      if (sliceSets) {
        const updatedSliceSets = sliceSets.map(sliceSet => ({
          ...sliceSet,
          slices: sliceSet.slices.filter(slice => tagNames.includes(slice.tagName))
        }));

        buildingBlockConfig.buildingBlockDef.sliceDef = { sliceSets: updatedSliceSets };
        shouldModifySliceSpec = true;
      }
    }

    if (widgetConfig && sliceSpec?.metricId) {
      const { metrics } = widgetConfig.dataDefinition;
      const metricToModify = metrics[sliceSpec.metricId];

      if (metricToModify) {
        // Handle userServiceField type
        if (metricToModify.sourceType === "userServiceField") {
          const { sliceSets } = metricToModify.userServiceFieldMetricConfig || {};
          if (sliceSets) {
            metricToModify.userServiceFieldMetricConfig.sliceSets = sliceSets.map(sliceSet => ({
              ...sliceSet,
              slices: sliceSet.slices.filter(slice => tagNames.includes(slice.tagName))
            }));
          }
        }
        // Handle expression type
        else if (metricToModify.sourceType === "expression") {
          const metricIdsSet = new Set<string>();
          const sliceSpecs: SliceSpec[] = [];

          extractMetricIdFromExpressionMetricConfig(metricToModify.expressionMetricConfig, metricIdsSet, sliceSpecs);

          sliceSpecs.forEach(spec => {
            if (spec.sliceSet) {
              spec.sliceSet = {
                slices: spec.sliceSet.slices.filter(slice => tagNames.includes(slice.tagName))
              };
            }

            const childMetric = metrics[spec.metricId];
            if (childMetric?.sourceType === "userServiceField") {
              const { sliceSets } = childMetric.userServiceFieldMetricConfig || {};
              if (sliceSets) {
                childMetric.userServiceFieldMetricConfig.sliceSets = sliceSets.map(sliceSet => ({
                  ...sliceSet,
                  slices: sliceSet.slices.filter(slice => tagNames.includes(slice.tagName))
                }));
              }
            }
          });
        }
        shouldModifySliceSpec = true;
      }
    }

    // Update main sliceSpec if needed
    if (shouldModifySliceSpec && sliceSpec?.sliceSet) {
      sliceSpec.sliceSet = {
        slices: sliceSpec.sliceSet.slices.filter(slice => tagNames.includes(slice.tagName))
      };
    }
  }

  static async getWidgetConfigBasedBizDataQuery(
    bizDataQuery: BizDataQuery,
    keepMetricsThatMatchSliceSpecOnly = false
  ): Promise<BizDataQuery> {
    const { bizDataQuery: resultBizDataQuery } = await this.getWidgetConfigBasedBizDataQueryBase(
      bizDataQuery,
      keepMetricsThatMatchSliceSpecOnly
    );
    return resultBizDataQuery;
  }

  static async getWidgetConfigBasedBizDataQueryWithQuerySchema(
    bizDataQuery: BizDataQuery,
    keepMetricsThatMatchSliceSpecOnly = false
  ): Promise<{ bizDataQuery: BizDataQuery; querySchema: WidgetQuerySchema[] }> {
    const { bizDataQuery: modBizDataQuery, querySchema: initialQuerySchema } =
      await this.getWidgetConfigBasedBizDataQueryBase(bizDataQuery, keepMetricsThatMatchSliceSpecOnly);

    let querySchema = initialQuerySchema?.querySchema;

    if (!querySchema) {
      const { widgetConfigDto } = WidgetConfigUtils.getWidgetConfigDtoFromBizDataQuery(modBizDataQuery);
      const { data, error, message } = await exploreApiService.getQuerySchemaForDraftWidgetConfig(widgetConfigDto);
      if (error) {
        querySchema = [];
        logger.error("getWidgetConfigBasedBizDataQueryWithQuerySchema", message);
      } else {
        querySchema = data;
      }
    }

    return {
      bizDataQuery: modBizDataQuery,
      querySchema
    };
  }

  static getBizDataQueryBasedOnSliceSpec(bizDataQuery: BizDataQuery): BizDataQuery {
    const { widgetConfig, sliceSpec } = bizDataQuery || {};
    if (sliceSpec?.metricId && widgetConfig) {
      const metrics = bizDataQuery?.widgetConfig?.dataDefinition?.metrics || {};
      const baseMetric = metrics[sliceSpec?.metricId];

      if (baseMetric) {
        const componentMetricIds =
          baseMetric.sourceType === "expression"
            ? getMetricIdsForExpressionMetric(baseMetric.expressionMetricConfig)
            : [];
        const metricIds = [sliceSpec?.metricId, ...componentMetricIds];
        const existingMetricIds = Object.keys(metrics);
        existingMetricIds.forEach(metricId => {
          if (!metricIds.includes(metricId)) {
            delete metrics[metricId];
          }
        });
      }
    }

    return bizDataQuery;
  }

  static getNameFromBizDataQuery(bizDataQuery: BizDataQuery) {
    const { widgetConfig, sliceSpec, labels, id, buildingBlockConfig } = bizDataQuery || {};
    const { metricId = "" } = sliceSpec || {};

    if (id) {
      return labels?.name || "";
    }

    if (buildingBlockConfig) {
      return buildingBlockConfig.name || "";
    }

    if (widgetConfig) {
      const metrics = widgetConfig.dataDefinition?.metrics || {};
      return metrics[metricId]?.name || widgetConfig.name || widgetConfig.labels?.name || "";
    }

    return "";
  }

  static setNameForBizDataQuery(bizDataQuery: BizDataQuery, name: string) {
    const { widgetConfig, sliceSpec, labels, id, buildingBlockConfig } = bizDataQuery || {};
    const { metricId = "" } = sliceSpec || {};

    if (id && labels) {
      labels.name = name;
    }

    if (buildingBlockConfig) {
      buildingBlockConfig.name = name;
    }

    if (widgetConfig) {
      const metrics = widgetConfig.dataDefinition?.metrics || {};
      if (metrics[metricId]) {
        metrics[metricId].name = name;
      }
      widgetConfig.name = name;
    }

    return "";
  }

  static getBizDataQueryClone(bizDataQuery: BizDataQuery, name?: string): BizDataQuery {
    if (bizDataQuery) {
      const clonedQuery = cloneDeep(bizDataQuery);

      if (clonedQuery.widgetConfig) {
        const pWidgetConfigDto = getDtoFromWidgetConfig(clonedQuery.widgetConfig);
        const { widgetConfigDto, metricIdLookup } = WidgetConfigUtils.getWidgetConfigClone(pWidgetConfigDto);
        clonedQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);
        if (clonedQuery.sliceSpec?.metricId) {
          const nMetricId = metricIdLookup[clonedQuery.sliceSpec.metricId];
          if (nMetricId) {
            clonedQuery.sliceSpec.metricId = nMetricId;
          } else {
            logger.error("BizDataQueryUtils", "Error finding new metricId in the lookup", {
              metricId: clonedQuery.sliceSpec.metricId,
              metricIdLookup
            });
          }
        }
      } else if (clonedQuery.buildingBlockConfig) {
        const newId = generateId();
        clonedQuery.buildingBlockConfig.id = newId;
        if (clonedQuery.sliceSpec?.buildingBlockConfigId) {
          clonedQuery.sliceSpec.buildingBlockConfigId = newId;
        }
      }

      if (name) {
        this.setNameForBizDataQuery(clonedQuery, name);
      }

      return clonedQuery;
    }

    return bizDataQuery;
  }

  static updateBizDataQuerySlices = (bizDataQuery: BizDataQuery, newSlices: Slice[]): BizDataQuery => {
    const updatedQuery = cloneDeep(bizDataQuery);
    const metricId = updatedQuery?.sliceSpec?.metricId;

    const slicesTagName = newSlices.map(slice => slice.tagName);

    if (!metricId) {
      return updatedQuery;
    }

    if (updatedQuery?.sliceSpec?.sliceSet) {
      updatedQuery.sliceSpec.sliceSet.slices = newSlices;
    }
    const overTag = updatedQuery?.sliceSpec?.postAgg as OverTagPostAgg;
    if (overTag?.overTagAgg) {
      overTag.overTagAgg.tagName = slicesTagName;
    }
    this.modifyUSFSliceSetForBizDataQueryByTag(updatedQuery, slicesTagName);

    return updatedQuery;
  };

  static getKPIClone(kpi: KPI, newKpiId: string): KPI {
    const clonedKPI = cloneDeep(kpi);

    const newName = `[CLONE] ${clonedKPI.name}`;
    if (clonedKPI.bizDataQuery) {
      clonedKPI.bizDataQuery = this.getBizDataQueryClone(clonedKPI.bizDataQuery, newName);
    }

    clonedKPI.id = newKpiId;
    clonedKPI.name = newName;
    clonedKPI.bizDataQuery.kpiId = newKpiId;
    return clonedKPI;
  }

  static getGenerateDemoData(bizDataQuery: BizDataQuery) {
    if (bizDataQuery) {
      if (bizDataQuery?.widgetConfig) {
        const { isIncomplete, overallStatus } = bizDataQuery?.widgetConfig?.mappingStatus || {};
        return isIncomplete || overallStatus === OverallMappingStatus.PENDING;
      }
    }

    return false;
  }

  static getEntityTypeAndEventTypeFromBizDataQuery = (bizDataQuery: BizDataQuery) => {
    const { widgetConfig: bdqWidgetConfig, buildingBlockConfig, idProps: bdqIdProps } = bizDataQuery || {};

    const { bizIdProps: bbcIdProps } = buildingBlockConfig || {};
    const { userServiceEntityId, bizEntityType } = bdqWidgetConfig || {};

    const { eventTypeId, entityTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(
      bdqIdProps || bbcIdProps
    );

    return {
      eventTypeId: eventTypeId || userServiceEntityId,
      entityTypeId: entityTypeId || bizEntityType
    };
  };

  /**
   * Updates the state monitoring configuration for all UserServiceField metrics in a BizDataQuery
   * @param bizDataQuery The BizDataQuery to update
   * @param userServiceFieldState The new state monitoring configuration to apply
   * @returns A new BizDataQuery with the updated state monitoring configuration
   */
  static updateUserServiceFieldStateInBizDataQuery = (
    bizDataQuery: BizDataQuery,
    userServiceFieldState: UserServiceFieldStateToMonitor
  ): BizDataQuery => {
    if (!bizDataQuery?.widgetConfig) {
      return bizDataQuery;
    }

    // Create a deep clone of the BizDataQuery to avoid modifying the original
    const updatedBizDataQuery = cloneDeep(bizDataQuery);

    // Get the DTO from the widget config
    const widgetConfigDto = getDtoFromWidgetConfig(updatedBizDataQuery.widgetConfig);

    if (!widgetConfigDto?.dataDefinition?.metrics) {
      return updatedBizDataQuery;
    }

    const { metrics } = widgetConfigDto.dataDefinition;

    // Update all UserServiceField metrics
    Object.values(metrics).forEach(metric => {
      if (metric.sourceType === "userServiceField" && metric.userServiceFieldMetricConfig) {
        // Set the new state monitoring configuration
        metric.userServiceFieldMetricConfig.objectStateToMonitor = userServiceFieldState;
      }
    });

    // Convert back to WidgetConfig
    updatedBizDataQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);

    return updatedBizDataQuery;
  };

  /**
   * Updates the journey step time query configuration for all UserServiceField metrics in a BizDataQuery
   * @param bizDataQuery The BizDataQuery to update
   * @param journeyConfig The new journey configuration to apply, or null to remove it
   * @returns A new BizDataQuery with the updated journey configuration
   */
  static updateJourneyConfigInBizDataQuery = (
    bizDataQuery: BizDataQuery,
    journeyConfig: JourneyStepTimeQueryConfig
  ): BizDataQuery => {
    if (!bizDataQuery?.widgetConfig) {
      return bizDataQuery;
    }

    // Create a deep clone of the BizDataQuery to avoid modifying the original
    const updatedBizDataQuery = cloneDeep(bizDataQuery);

    // Get the DTO from the widget config
    const widgetConfigDto = getDtoFromWidgetConfig(updatedBizDataQuery.widgetConfig);

    if (!widgetConfigDto?.dataDefinition?.metrics) {
      return updatedBizDataQuery;
    }

    const { metrics } = widgetConfigDto.dataDefinition;

    // Update all UserServiceField metrics
    Object.values(metrics).forEach(metric => {
      if (metric.sourceType === "userServiceField" && metric.userServiceFieldMetricConfig) {
        // Set the new journey configuration
        metric.userServiceFieldMetricConfig.journeyStepTimeQueryConfig = journeyConfig;
      }
    });

    // Convert back to WidgetConfig
    updatedBizDataQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);

    return updatedBizDataQuery;
  };

  /**
   * Extracts UserServiceField metric definitions from a BizDataQuery
   * @param bizDataQuery The BizDataQuery to extract from
   * @returns Array of UserServiceFieldMetricConfigDefinition objects
   */
  static getUserServiceFieldMetrics = (bizDataQuery: BizDataQuery): UserServiceFieldMetricConfigDefinition[] => {
    if (!bizDataQuery?.widgetConfig) {
      return [];
    }

    const widgetConfigDto = getDtoFromWidgetConfig(bizDataQuery.widgetConfig);

    if (!widgetConfigDto?.dataDefinition?.metrics) {
      return [];
    }

    const { metrics } = widgetConfigDto.dataDefinition;
    const userServiceFieldMetrics: UserServiceFieldMetricConfigDefinition[] = [];

    Object.values(metrics).forEach(metric => {
      if (metric.sourceType === "userServiceField") {
        userServiceFieldMetrics.push(metric as UserServiceFieldMetricConfigDefinition);
      }
    });

    return userServiceFieldMetrics;
  };

  static updateStateToMonitorInBizDataQuery = (
    bizDataQuery: BizDataQuery,
    stateToMonitor: UserServiceFieldStateToMonitor
  ): BizDataQuery => {
    if (!bizDataQuery?.widgetConfig) {
      return bizDataQuery;
    }

    // Create a deep clone of the BizDataQuery
    const updatedBizDataQuery = cloneDeep(bizDataQuery);
    const widgetConfigDto = getDtoFromWidgetConfig(updatedBizDataQuery.widgetConfig);

    if (!widgetConfigDto?.dataDefinition?.metrics) {
      return updatedBizDataQuery;
    }

    const { metrics } = widgetConfigDto.dataDefinition;

    // Update all UserServiceField metrics
    Object.values(metrics).forEach(metric => {
      if (metric?.sourceType === "userServiceField" && metric?.userServiceFieldMetricConfig) {
        metric.userServiceFieldMetricConfig.objectStateToMonitor = stateToMonitor;
      }
    });

    // Convert back to WidgetConfig
    updatedBizDataQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);

    return updatedBizDataQuery;
  };

  /**
   * Extracts Entity metric definitions from a BizDataQuery
   * @param bizDataQuery The BizDataQuery to extract from
   * @returns Array of EntityMetricConfigDefinition objects
   */
  static getEntityMetrics = (bizDataQuery: BizDataQuery): EntityMetricConfigDefinition[] => {
    if (!bizDataQuery?.widgetConfig) {
      return [];
    }

    const widgetConfigDto = getDtoFromWidgetConfig(bizDataQuery.widgetConfig);

    if (!widgetConfigDto?.dataDefinition?.metrics) {
      return [];
    }

    const { metrics } = widgetConfigDto.dataDefinition;
    const entityMetrics: EntityMetricConfigDefinition[] = [];

    Object.values(metrics).forEach(metric => {
      if (metric.sourceType === "entity") {
        entityMetrics.push(metric);
      }
    });

    return entityMetrics;
  };

  static updateTimeStampFieldInBizDataQuery = (bizDataQuery: BizDataQuery, timeStampField: BizField): BizDataQuery => {
    if (!bizDataQuery?.widgetConfig) {
      return bizDataQuery;
    }

    // Create a deep clone of the BizDataQuery
    const updatedBizDataQuery = cloneDeep(bizDataQuery);
    const widgetConfigDto = getDtoFromWidgetConfig(updatedBizDataQuery.widgetConfig);

    if (!widgetConfigDto?.dataDefinition?.metrics) {
      return updatedBizDataQuery;
    }

    const { metrics } = widgetConfigDto.dataDefinition;

    // Update all entity metrics
    Object.values(metrics).forEach(metric => {
      if (metric?.sourceType === "entity" && metric?.entityMetricConfig) {
        metric.entityMetricConfig.timeStampField = timeStampField;
      }
    });

    // Convert back to WidgetConfig
    updatedBizDataQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);

    return updatedBizDataQuery;
  };

  static updateTimeStampFieldInBizDataQueryV2 = (
    bizDataQuery: BizDataQuery,
    timeStampField: UserServiceField
  ): BizDataQuery => {
    if (!bizDataQuery?.widgetConfig) {
      return bizDataQuery;
    }

    // Create a deep clone of the BizDataQuery
    const updatedBizDataQuery = cloneDeep(bizDataQuery);
    const widgetConfigDto = getDtoFromWidgetConfig(updatedBizDataQuery.widgetConfig);

    if (!widgetConfigDto?.dataDefinition?.metrics) {
      return updatedBizDataQuery;
    }

    const { metrics } = widgetConfigDto.dataDefinition;

    // Update all entity metrics
    Object.values(metrics).forEach(metric => {
      if (metric?.sourceType === "userServiceField" && metric?.userServiceFieldMetricConfig) {
        metric.userServiceFieldMetricConfig.timeStampField = timeStampField;
      }
    });

    // Convert back to WidgetConfig
    updatedBizDataQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);

    return updatedBizDataQuery;
  };

  private static async getWidgetConfigBasedBizDataQueryBase(
    bizDataQuery: BizDataQuery,
    keepMetricsThatMatchSliceSpecOnly = false
  ): Promise<{ bizDataQuery: BizDataQuery; querySchema: WidgetQuerySchemaResponse }> {
    const { buildingBlockConfig, widgetConfig, id, sliceSpec, idProps, labels, metricUserServiceFilters } =
      bizDataQuery || {};
    const shouldFetchWidgetConfig = !widgetConfig && id;
    const shouldOverrideFromBuildingBlockConfig = !widgetConfig && !!buildingBlockConfig;

    let modBizDataQuery = bizDataQuery;
    let querySchema = null;

    if (shouldFetchWidgetConfig) {
      const { entityTypeId, eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(idProps);
      const { data, error, message } = await exploreApiService.getWidgetConfig(
        eventTypeId || labels?.eventTypeId,
        entityTypeId || labels?.entityTypeId,
        id
      );
      if (error) {
        logger.warn("Transform KPI bizDataQuery", "Error fetching widgetConfig", error);
        throw Error(message);
      } else {
        querySchema = data.querySchema;
        const widgetConfig = getWidgetConfigFromDto(data.widgetConfig);
        const { metrics } = widgetConfig?.dataDefinition || {};
        const metric = metrics?.[bizDataQuery?.sliceSpec?.metricId];
        modBizDataQuery = {
          ...bizDataQuery,
          id: null,
          widgetConfig,
          buildingBlockConfig: null,
          sliceSpec: {
            ...sliceSpec,
            metricId: metric ? bizDataQuery?.sliceSpec?.metricId : id,
            buildingBlockConfigId: null
          }
        };
      }
    } else if (shouldOverrideFromBuildingBlockConfig) {
      const widgetConfigDto = WidgetConfigUtils.getWidgetConfigDtoFromBuildingBlockConfig(
        buildingBlockConfig,
        metricUserServiceFilters
      );
      modBizDataQuery = {
        ...bizDataQuery,
        widgetConfig: getWidgetConfigFromDto(widgetConfigDto),
        buildingBlockConfig: null,
        id: null,
        sliceSpec: {
          ...sliceSpec,
          metricId: sliceSpec.buildingBlockConfigId,
          buildingBlockConfigId: null
        }
      };
    }

    if (keepMetricsThatMatchSliceSpecOnly && sliceSpec?.metricId) {
      const metrics = modBizDataQuery?.widgetConfig?.dataDefinition?.metrics || {};
      const baseMetric = metrics[sliceSpec?.metricId];

      if (baseMetric) {
        const componentMetricIds =
          baseMetric.sourceType === "expression"
            ? getMetricIdsForExpressionMetric(baseMetric.expressionMetricConfig)
            : [];
        const metricIds = [sliceSpec?.metricId, ...componentMetricIds];
        const existingMetricIds = Object.keys(metrics);
        existingMetricIds.forEach(metricId => {
          if (!metricIds.includes(metricId)) {
            delete metrics[metricId];
          }
        });
      }
    }

    return {
      bizDataQuery: modBizDataQuery,
      querySchema
    };
  }
}
