import React, { useEffect } from "react";
import mermaid from "mermaid";
import { generateId } from "../../utils";

// Initialize mermaid configuration
mermaid.initialize({
  startOnLoad: true,
  securityLevel: "loose",
  fontFamily: "sans-serif",
  themeVariables: {
    lineColor: "#ffffff",
    primaryColor: "#ffffff",
    backgroundColor: "#101822"
  },
  theme: "null",
  look: "classic"
});

interface MermaidChartRendererProps {
  chart: string;
  id?: string; // pass a new id ,used for re-rendering when mermaid is updated
}

const MermaidChartRenderer: React.FC<MermaidChartRendererProps> = props => {
  const { chart, id = "" } = props;

  const chartId = id || `mermaid-renderer-${generateId()}`;

  useEffect(() => {
    document.getElementById(chartId)?.removeAttribute("data-processed");
    mermaid.contentLoaded();
  }, [chart, chartId]);

  return <div className="mermaid inc-flex-center-vertical inc-flex-center-horizontal">{chart}</div>;
};

export default MermaidChartRenderer;
