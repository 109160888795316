import { CompanySampleData, CompanySummary } from "../../../../../features/company-selector-v2/types";
import { BizProcess } from "../../business-process";
import { LabelFragment } from "../../chat";
import { ConfiguredConnectorStream } from "../../entity-mapping";
import { MetricSummary } from "../../event-mapping";
import { CauseSummary, ResponseInfo } from "../../explore";
import { OpCreationConfig } from "../../operationalise";
import { Source } from "../../tenant-ai/types";
import {
  EventTypeSettingsList,
  KPI,
  KnowledgeBaseSettings,
  KnowledgeBaseSettingsList,
  MemberTypeSettings,
  UseCaseConfig,
  UseCaseDataQueryConfig,
  UseCaseSummary
} from "./use-case";
import { Feed } from "./use-case-feed";
import {
  CustomWidgetV2,
  InsightDescription,
  InsightDescriptionList,
  KPISummary,
  RecommendedUseCase,
  SuggestedUsecaseV2,
  UsecaseDefinitionSummary,
  UseCaseV2,
  VerticalIdentifier,
  WidgetDescription
} from "./use-case-v2";

export interface SuggestUsecasesResponse {
  useCases: SuggestedUsecaseV2[];
}

export interface UseCaseSuggestionsRequest {
  chatId: string;
  verticalIdentifier: VerticalIdentifier;
}

export interface SuggestKPIRequest {
  useCaseConfig: UseCaseConfig;
  chatId: string;
  verticalId: string;
  subVerticalId: string;
  companyName: string;
}

export interface SuggestKPIResponse {
  kpiSummaryList: KPISummary[];
  responseInfo: ResponseInfo;
  statusCode: number;
}

export interface SuggestDashboardsRequest {
  useCaseConfig: UseCaseConfig;
  chatId: string;
  verticalId: string;
  subVerticalId: string;
  companyName: string;
  numberOfDashboards: number;
}

export interface SuggestDashboardsResponse {
  dashboards: Record<string, string>;
  responseInfo: ResponseInfo;
  statusCode: number;
}

export type SuggestPatternsRequest = {
  useCaseId: string;
  companyName: string;
  verticalId: string;
  subVerticalId: string;
};

export type SuggestPatternsResponse = {
  patterns: string[];
  responseInfo: ResponseInfo;
  statusCode: number;
};

export interface KPIDataQueryDefRequest {
  verticalIdentifier: VerticalIdentifier;
  useCaseName: string;
  kpiName: string;

  //oneOf
  useCaseId?: string;
  useCaseConfig?: UseCaseConfig;
}

export interface KPIInsightsRequest {
  kpiName: string;
  kpiDescription: string;

  useCaseName: string;
  useCaseDescription: string;

  verticalIdentifier: VerticalIdentifier;
  involvedEvents: string[];
  insightsCount: number;

  //oneOf
  useCaseId?: string;
  useCaseConfig?: UseCaseConfig;

  kpiId: string;
}

export interface KPIInsightsResponse {
  kpiName: string;
  useCaseName: string;
  insightDescriptionList: InsightDescriptionList;
  responseInfo: ResponseInfo;
  statusCode: number;
  kpiId: string;
}

export interface SuggestUsecasesResponseV2 {
  useCaseRecommendations: RecommendedUseCase[];
  responseInfo: ResponseInfo;
  statusCode: number;
}

export interface UseCaseSuggestionSummaryRequest {
  useCaseRecommendation: RecommendedUseCase;
}

export interface UseCaseSuggestionSummaryResponse {
  useCaseSummary: UsecaseDefinitionSummary;
  responseInfo: ResponseInfo;
  statusCode: number;
}

export interface SuggestAddUsecaseRequestV2 {
  widgetDescriptions: WidgetDescription[];
}

export interface SuggestAddUsecaseResponseV2 {
  rawUseCase: UseCaseV2;
  queries: UseCaseDataQueryConfig[];
}

export interface RegenerateSuggestedQueryRequestV2 {
  rawUseCase: UseCaseV2;
  queryId: string;
}

export interface RegenerateSuggestedQueryResponseV2 {
  query: UseCaseDataQueryConfig;
}

export interface GenerateSuggestedUsecaseSummaryRequestV2 {
  rawUseCase: UseCaseV2;
  queries: UseCaseDataQueryConfig[];
}

export interface GenerateSuggestedUsecaseSummaryResponseV2 {
  useCase: SuggestedUsecaseV2;
}

export interface AddWidgetRequest {
  query: UseCaseDataQueryConfig;
}

export interface AddWidgetResponse {
  query: UseCaseDataQueryConfig;
}

export interface AddOp10zeRequest {
  opIds: string[];
}

export interface AddOp10zeResponse {
  queries: UseCaseDataQueryConfig[];
}

type CommonRequestProps = {
  verticalId: string;
  subVerticalId: string;

  companyName?: string;
  strategy?: string;
};

export interface SuggestUsecaseRequest extends CommonRequestProps {
  name: string;
}

export interface SuggestDataQueryDefRequest extends CommonRequestProps {
  useCaseName: string;
  usecaseId: string;
  kpiId: string;
  // oneOf
  widgetDescription?: WidgetDescription;
  insightDescription?: InsightDescription;
}

export interface SuggestedDataQueryResponse {
  query: UseCaseDataQueryConfig;
  widget: CustomWidgetV2;
}

export interface UseCaseSaveRequest extends CommonRequestProps {
  id?: string;
  useCaseConfig: UseCaseConfig;
}

export type UpdateUseCaseMetadataRequest = Partial<{
  name: string;
  description: string;
  icon: string;
  subVertical: string;
  isPrivate: boolean;
}>;

export interface EditUseCaseRequest {
  id: string;
  useCaseConfig: UseCaseConfig;
}

export interface UseCaseSaveResponse {
  /**
   * @deprecated Use useCaseConfig instead
   */
  useCaseSummary: UseCaseSummary;

  useCaseConfig: UseCaseConfig;
  useCaseId: string;
  responseInfo: ResponseInfo;
  statusCode: number;
}

export interface GetUseCaseBizProcessRequest extends CommonRequestProps {
  widgetId?: string; // Exists for widget's bizProcess case. We can choose to ignore this for now based on complexity
}

export interface GetUseCaseBizProcessResponse {
  bizProcess: BizProcess;
  responseInfo: ResponseInfo;
  statusCode: number;
}

export interface UseCaseCatalogListRequest {
  verticalId: string;
  subVerticalId: string;
  companyName: string;
}

export interface UsecaseUpsertResponse {
  id: string;
  message: string;
  status: string;
  errors: string[];
  lookupData?: Record<string, string>;
  memberTypeSettings?: MemberTypeSettings[];
}

export interface UpdateEventTypeSettingsRequest {
  eventTypeSettingsList: EventTypeSettingsList;
}

export interface AddKnowledgeBaseRequest {
  configuredConnectorStream?: ConfiguredConnectorStream;
  knowledgeBaseSettings?: KnowledgeBaseSettings;
  companyName?: string;
  description?: string;
}

export interface UpdateDataSettingsRequest {
  eventTypeSettingsList: EventTypeSettingsList;
  knowledgeBaseSettingsList: KnowledgeBaseSettingsList;
}

export interface AddMemberRequestList {
  addMemberRequestList: AddMemberRequest[];
}
export interface AddMemberRequest {
  userId: string;
}

export interface UseCaseOp10zeSearchRequest {
  useCaseIds: string[];
  companyName: string;
}

export type UseCaseOp10zeQueryParams = Partial<{
  includeHidden: boolean;
}>;

export interface UseCaseOp10zeMap {
  opCreationConfigs: Record<string, OpCreationConfig>;
}

export interface UseCaseOp10zeSearchResponse {
  useCaseOp10zeMap: Record<string, UseCaseOp10zeMap>;
  responseInfo: ResponseInfo;
  statusCode: number;
}

export interface UseCaseFeedResponse {
  feedList: Feed[];
  labels: LabelFragment[];
  responseInfo: ResponseInfo;
  statusCode: number;
}

export interface UseCaseFeedResponseList {
  feedResponseList: UseCaseFeedResponse[];
  significantFeed?: UseCaseFeedResponse;
  responseInfo: ResponseInfo;
  statusCode: number;
}

export interface GetUseCaseFeedsResponse {
  feedResponses: Record<string, UseCaseFeedResponse>;
}

export interface GetUseCaseFeedsRequest {
  useCaseIds: string[];
}

export type AddKPIRequest = {
  kpi: KPI;
};

export type KpiList = {
  kpis: KPI[];
};
export type KpisByUseCaseResponse = {
  useCaseToKpis: Record<string, KpiList>;
};

//updated proto
export interface KnowledgeSummary {
  verticalIdentifier: VerticalIdentifier;
  companySummary: CompanySummary;
  eventType: EventTypeSummary[];
  entityType: EntityTypeSummary[];
  useCase: UseCaseSummary[];
  cause: CauseSummary[];
  metric: MetricSummary[];
  source: Source;
  sourceIdentifier: string;
  connectorId: string;
  upsertTimestamp: number;
  companySampleData: CompanySampleData;

  //journey: JourneySummary[];
  //action: ActionSummary[];
  //insight: InsightSummary[];
}

export interface EventTypeSummary {
  name: string;
  source: Source;
  dimension: DimensionSummary[];
  references: string[];
  icon: string;
  description: string;
  eventTypeId: string;
  summarizedSchema: string;
}

export interface EntityTypeSummary {
  name: string;
  source: Source;
  dimension: DimensionSummary[];
  references: string[];
  icon: string;
  description: string;
  entityTypeId: string;
  summarizedSchema: string;
}

export interface DimensionSummary {
  name: string;
  source: Source;
  sampleValue: string[];
}

export interface IndexSyncStatus {
  status: SyncStatus;
  percentCompleted: number;

  uiMsg?: string;
}

export enum SyncStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED"
}
