import { getInceptionTheme } from "@inception/ui";
import { getLuminance, parseToRgb } from "polished";

export const traceLatencyHistogramColors: string[] = ["#264653", "#2A9D8F", "#E9C46A"];

export default function getChartColor(index: number, comparison?: boolean) {
  const themeSpecificChartColors = getInceptionTheme().chartColors;
  const { length } = themeSpecificChartColors;

  let i = comparison ? length - index - 1 : index;
  i = Math.abs(i) % length;
  return themeSpecificChartColors[i];
}

export function getNumChartColors() {
  const themeSpecificChartColors = getInceptionTheme().chartColors;
  const { length } = themeSpecificChartColors;
  return length;
}

function isColorSimilar(color1: string, color2: string, similarityThreshold = 0.1): boolean {
  try {
    const rgb1 = parseToRgb(color1);
    const rgb2 = parseToRgb(color2);

    // Compare luminance difference
    const luminanceDiff = Math.abs(getLuminance(color1) - getLuminance(color2));

    // Compare RGB distance
    const colorDiff =
      Math.abs(rgb1.red - rgb2.red) + Math.abs(rgb1.green - rgb2.green) + Math.abs(rgb1.blue - rgb2.blue);

    return luminanceDiff < similarityThreshold || colorDiff < 30;
  } catch {
    return false; // In case of invalid color format
  }
}

export function getRandomColor(colorsToExclude: string[] = []): string {
  const themeSpecificChartColors = getInceptionTheme().chartColors;

  // Filter colors based on exclusions and similarity
  const filteredColors = themeSpecificChartColors.filter(
    color =>
      !colorsToExclude.includes(color) && !colorsToExclude.some(excludedColor => isColorSimilar(color, excludedColor))
  );

  // If all colors are excluded, fallback to the full color list
  const finalColors = filteredColors.length > 0 ? filteredColors : themeSpecificChartColors;

  const i = Math.floor(Math.random() * finalColors.length);
  return finalColors[i];
}

// const integrationTagColors: string[] = [
//   "#E0F9D7",
//   "#FCEACA",
//   "#CFFAFF",
//   "#F9E2D2",
//   "#F9BA8F",
//   "#FCE2DE",
//   "#BADFF4",
//   "#ffbf71",
//   "#F9D9F9",
//   "#DEDAF7",
//   "#B7DBAB",
//   "#F4D598",
//   "#F29191",
// ];

export function getIntegrationTagColor(index: number) {
  const themeSpecificChartColors = getInceptionTheme().chartColors;
  const i = Math.abs(index) % themeSpecificChartColors.length;
  return themeSpecificChartColors[i];
}
