import { IncFaIconName, ISaxIconName } from "@inception/ui";
import {
  AuditInfo,
  BizDataQuery,
  BizDataQueryList,
  BizFieldWithMetaList,
  DemoDataGenInfo,
  ImpactedWidgetList,
  IncidentSliceContribution,
  Op10zeMappingStatus,
  ResponseInfo,
  TimeObj,
  UserServiceField,
  UserServiceFieldSlice,
  UserServiceFieldSliceSet,
  UserServiceFilterList
} from "../../explore";
import { DataType, EntityPropertyValue, LogicalOperator } from "../../../../core";
import { LabelFragment, SeedInput } from "../../chat";
import {
  ActionRunMode,
  BizIdProps,
  CauseMetaData,
  OpSchedule,
  OpType,
  TrendType,
  WhatsNewConfig
} from "./CommonTypesV2";
import { ActionEvalSpec, BizActionConfig, ParamValueTemplate } from "./ActionTypesV2";
import { AlertStatus } from "./alerts";
import { AlertTemplateDef } from "./ActionTypes";
import { OpCloseTrigger, OpStartTrigger, OpTriggerCondition } from "./triggers";
import { CompareOperator, HistoryToConsider, OpThreshold, OpThresholdSeasonality } from "./thresholds";
import { SuppressionConfigDef } from "./suppressions";
import { OpCreationFragment } from "./OpFragmentTypes";
import { OpCreationFragmentV2List } from "./OpFragmentV2";
import { SeasonalityOverrideDef } from "./seasonality";

export enum Op10zeStage {
  production = "production",
  draft = "draft",
  staging = "staging",
  /**
   * @deprecated Added only for UI purpose, DO NOT use in payloads
   */
  all = "all"
}

export type AlertCount = {
  alertStatus: AlertStatus;
  count: string; // Stringified number
  url: string;
};

export type AlertCountList = {
  count: AlertCount[];
};

export enum OpInsightType {
  REAL_TIME_DETECTION = "REAL_TIME_DETECTION",
  TREND_DETECTION = "TREND_DETECTION",
  JOURNEY_ANALYSIS = "JOURNEY_ANALYSIS"
}

export type OpMetadata = {
  isOpportunity: boolean;
  spikeDropCompareOperator: CompareOperator;
  insightType: OpInsightType;
  seedInput?: SeedInput;
  importance?: string;
  causeMetaData?: CauseMetaData;
  icon?: IncFaIconName | ISaxIconName;
  labelFragments?: LabelFragment[];
};

export interface OpCreationConfig extends Partial<OpTriageConfig> {
  name: string;

  opCreationConfigDef: OpCreationConfigDef;
  outlierConfig?: OutlierDetectionConfigDef;
  opAnalysisConfig?: OpAnalysisCreationConfig;
  trendDetectionConfigDef?: OpTrendDetectionConfigDef;
  opCreationConfigExpr: OpCreationExpr;
  idProps: BizIdProps;
  description: string;

  runBooks: OpRunBook[];
  bizActions: Record<string, BizActionConfig>;

  isDisabled?: boolean;
  isDraft?: boolean;
  disableOpEdit?: boolean;

  labels?: Record<string, string>;
  internalConfigId?: string;
  opConfigProps?: Record<string, string>;
  createdBy: AuditInfo;
  lastUpdatedBy: AuditInfo;

  stage: Op10zeStage;
  version?: string;

  simulations?: Simulation[];
  history?: Record<number, OpCreationConfig>;
  opCreationConfigMetadata?: OpMetadata;
  opMappingStatus?: Op10zeMappingStatus;
  demoDataGenInfo?: DemoDataGenInfo;
  actionEvalSpec?: ActionEvalSpec;

  /**
   * @deprecated Use opCreationFragmentV2List
   */
  opFragment?: OpCreationFragment;
  opCreationFragmentV2List?: OpCreationFragmentV2List;
}

export interface BizDataOperationalize {
  opConfigId: string;
  opCreationConfig: OpCreationConfig;
}

export type OpTriageConfigImpactedWidgets = Pick<ImpactedWidgetList, "impactedWidgets">;

export enum OpTriageConfigType {
  UNSET = "op_triage_config_type_unset",
  IMPACTED_WIDGETS = "impacted_widgets",
  CONTRIBUTING_FIELDS = "contributing_fields",
  IMPACT_FIELDS = "impact_fields"
}

export interface OpTriageConfig {
  impactedWidgets: OpTriageConfigImpactedWidgets;
  diagnosticFields: UserServiceFieldSliceSet;
  impactFields: UserServiceFieldSliceSet;
}

export type SimulationLabels = Record<string, string> & {
  isPrimary: string; // stringified boolean
};

export interface Simulation {
  simulationId: string;
  name: string;
  description: string;
  createdBy: AuditInfo;
  opCreationConfig: OpCreationConfig;
  labels: SimulationLabels;
}

export interface OpRunBook {
  name: string;
  desc: string;
  steps: OpRunStep;
  relatedBizDataQueries: BizDataQueryList;
  createdBy: AuditInfo;
  lastUpdatedBy: AuditInfo;
}

export interface OpRunStep {
  desc: string;
  diagnosticFields: UserServiceField[];
  filters: UserServiceFilterList;
  nextStep: OpRunStep;
  createdBy: AuditInfo;
  lastUpdatedBy: AuditInfo;
}

export interface OpCreationExpr {
  leftConfig: OpCreationConfig;
  op: LogicalOperator;
  rightConfig: OpCreationConfig;
}

export type OutlierType = "NA" | "TIME_BASED_OUTLIER" | "PEER_BASED_OUTLIER" | "CONTRIBUTION_BASED_OUTLIER" | "ALL";

export interface OpBizDataQuery {
  bizDataQuery: BizDataQuery;
  rollingFunction: string;
  rollingFreq: TimeObj;
  name: string; //used for kpi name
}

export enum OpAnalysisType {
  "MULTI_DIM" = "MULTI_DIM",
  "TRENDS" = "TRENDS"
}

export interface OpAnalysisCreationConfig {
  schedule: OpSchedule;
  kpis: Record<string, OpBizDataQuery>; // kpis for which we need to weekly analysis
  rollingFreq: TimeObj;
  dimensionsToAnalyze: UserServiceFieldSlice[];
  diagnosticFields: UserServiceFieldSlice[];
  dimensionSetsToAnalyze: UserServiceFieldSliceSet[]; // dimensions to analyze + trends

  compareToOffset: TimeObj; // compare to offset
  diagnosticKpis: Record<string, DiagnosticKPIs>; // kpis for which can be used to generate the top level summary + table data
  templateFormat: string;
  templateDefStr: string;
  storyTemplates?: StoryTemplateStr[];
  kpiLevelSuppression?: Record<string, SuppressionConfigDef>;
  suppression: SuppressionConfigDef[];
  analysisType: OpAnalysisType[];
}

export type OpTrendDetectionConfigDef = {
  schedule: OpSchedule;
  bizDataQuery: OpBizDataQuery;
  suppression: SuppressionConfigDef[];
  historyToConsider: HistoryToConsider;
  trendType: TrendType;
  startTrigger: OpStartTrigger;
};

export interface StoryTemplateStr {
  name: string;
  templateJsonStr: string;
}

export interface GetDefaultDiagnosticKpiRequest {
  kpi: OpBizDataQuery;
}

export interface DefaultDiagnosticKpiResponse {
  relatedKpis: DiagnosticKPIs;
}

export type DiagnosticKPIs = {
  kpi: OpBizDataQuery[];
};

export interface OutlierDetectionConfigDef {
  outlierType: OutlierType;
  bizDataQuery: OpBizDataQuery;
  schedule: OpSchedule;
  outlierDetectionProps: Record<string, string>;
}

export interface OpCreationConfigDef {
  schedule: OpSchedule;
  bizDataQuery: BizDataQuery;
  rollingFunction: string;
  rollingFreq: TimeObj;

  threshold: OpThreshold;
  internalConfigId: string;

  suppression: SuppressionConfigDef[];
  whatsNewConfig: WhatsNewConfig;
  seasonalityOverrides: SeasonalityOverrideDef[];

  // OneOf
  triggerCondition?: OpTriggerCondition;
  oneTimeTrigger?: boolean;
  startTrigger?: OpStartTrigger;

  closeTrigger?: OpCloseTrigger;
}

export type QueryWindowTimeRange = {
  startTimeMillis: string;
  endTimeMillis: string;
};

export type QueryWindowCompareTimeRange = Record<string, QueryWindowTimeRange>;

export type QueryWindow = {
  timeRange?: QueryWindowTimeRange;
  compareTimeRange?: QueryWindowCompareTimeRange;
};

export type OpSchema = {
  opId: string;
  opName: string;
  version: number;

  idProps: BizIdProps;

  relatedJourneys: string[];

  alertingEventFields: UserServiceFieldSliceSet;
  alertingEntityFields: BizFieldWithMetaList;
  impactFields: UserServiceFieldSliceSet;
  impactedWidgets: ImpactedWidgetList;
  impactedFunnels: ImpactedWidgetList;
  sliceContribution: IncidentSliceContribution[];
  entityLookupData: Record<string, string>;

  diagnosticEventFields: UserServiceFieldSliceSet;
  diagnosticEntityFields: BizFieldWithMetaList;

  defaultCompareTime: TimeObj;

  spikeCase: boolean;
  isSpikePositive: boolean;
  isOpportunity: boolean;
  subType: DataType;
  queryWindow?: QueryWindow;
  opType: OpType;

  rollingFrequencyMillis: string;
  lastPointFrequencyMillis?: string;
  frequency: TimeObj;
  lookback: TimeObj;
  seasonality: OpThresholdSeasonality;
  dataType: DataType;

  responseInfo: ResponseInfo;
  statusCode: number;
};

export interface BizActionParamValuesRequest {
  opId?: string;
  opConfig?: OpCreationConfig;
  actionRunMode: ActionRunMode;
}

interface ParamValue {
  id: string;
  name: string;
  props: Record<string, EntityPropertyValue>;
  alertTemplateDef: AlertTemplateDef;
}

export interface ParamValuesList {
  value: ParamValue[];
}

export interface BizActionParamValuesResponse {
  paramValues: Record<string, ParamValuesList>;
  paramDefValue: Record<string, ParamValueTemplate>;
}

export type OpProjection = Record<string, any>;

export type BizOpQuery = {
  bizDataOperationalize: BizDataOperationalize;
  projections: OpProjection[];
  incidentId: string;
};
