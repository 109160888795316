import { createAction } from "@reduxjs/toolkit";
import { clone } from "lodash";
import appConfig from "../../../../appConfig";
import {
  KpiList,
  MemberSettings,
  UseCaseConfig,
  UseCaseDataQueryConfig,
  UseCaseSummaryWithSchema
} from "../../../services/api";
import { OpCreationConfig } from "../../../services/api/operationalise";
import { VerticalConfig, VerticalConfigState, VerticalUseCaseSummary, VerticalUseCaseUnderlyingInfo } from "./types";
import { getUseCaseColor } from "./utils";

const defaultVerticalConfig: VerticalConfig = {
  companyName: null,
  subVerticalId: null,
  verticalId: null,
  verticalIds: [],
  useCaseId: "",
  companyInfos: [],
  useCaseDTOs: [],
  memberUseCaseDTOs: [],
  useCaseToUnderlyingInfo: {},
  useCaseToKpis: {}
};

const defaultVerticalConfigState: VerticalConfigState = {
  verticalConfig: defaultVerticalConfig,
  isFetching: true,
  isSchemaFetching: false,
  useCaseSchemaExists: false
};

const SET_VERTICAL_CONFIG = "set-vertical-config";
const SET_SELECTED_SUB_VERTICAL = "set-selected-sub-vertical";
const SET_SELECTED_USE_CASE = "set-use-case";
const SET_COMPANY_NAME = "set-company-name";
const SET_USECASE_TO_KPIS = "set-usecase-to-kpis";
const SET_SCHEMA_FETCHING = "set-schema-fetching";
const UPDATE_USE_CASE_MAP_WITH_SCHEMA = "update-use-case-map-schema";

const ADD_USE_CASE_SCHEMA = "add-use-case-schema";
const DELETE_USE_CASE_SCHEMA = "delete-use-case-schema";
const UPDATE_USE_CASE_SCHEMA = "update-use-case-schema";

const DELETE_OP10ZE_FROM_USE_CASE = "delete-op10ze-from-use-case";
const UPDATE_OP10ZE_OF_USE_CASE = "update-op10ze-of-use-case";

const ADD_WIDGET_TO_USE_CASE = "add-widget-to-use-case";

const UPDATE_USE_CASE_UNDERLYING_INFO = "update-use-case-underlying-info";
const ADD_TO_MY_USE_CASES = "add-to-my-use-cases";
const REMOVE_FROM_MY_USE_CASES = "remove-from-my-use-cases";

export const setVerticalConfigAction = createAction<VerticalConfigState>(SET_VERTICAL_CONFIG);
export const setSelectedSubVerticalAction = createAction<SetSubVerticalPayload>(SET_SELECTED_SUB_VERTICAL);
export const setSelectedUseCaseAction = createAction<string>(SET_SELECTED_USE_CASE);
export const setCompanyNameAction = createAction<string>(SET_COMPANY_NAME);
export const setUseCaseToKpisAction = createAction<Record<string, KpiList>>(SET_USECASE_TO_KPIS);
export const setSchemaFetchingAction = createAction<boolean>(SET_SCHEMA_FETCHING);
export const updateVerticalUseCaseMapWithSchemaAction = createAction<Record<string, UseCaseConfig>>(
  UPDATE_USE_CASE_MAP_WITH_SCHEMA
);

export const addUseCaseSchemaAction = createAction<AddUseCasePayload>(ADD_USE_CASE_SCHEMA);
export const updateUseCaseSchemaAction = createAction<UpdateUseCasePayload>(UPDATE_USE_CASE_SCHEMA);
export const deleteUseCaseSchemaAction = createAction<string>(DELETE_USE_CASE_SCHEMA);

export const deleteOp10zeFromUseCaseAction = createAction<DeleteOp10zePayload>(DELETE_OP10ZE_FROM_USE_CASE);
export const updateOp10zeOfUseCaseAction = createAction<UpdateOp10zePayload>(UPDATE_OP10ZE_OF_USE_CASE);

export const addWidgetToUseCaseAction = createAction<AddWidgetToUseCasePayload>(ADD_WIDGET_TO_USE_CASE);

export const updateUseCaseUnderlyingInfo = createAction<Record<string, VerticalUseCaseUnderlyingInfo>>(
  UPDATE_USE_CASE_UNDERLYING_INFO
);
export const addToMyUseCasesAction = createAction<AddToMyUseCasesPayload>(ADD_TO_MY_USE_CASES);
export const removeFromMyUseCasesAction = createAction<RemoveFromMyUseCasesPayload>(REMOVE_FROM_MY_USE_CASES);

const updateAppConfig = (state: VerticalConfig) => {
  appConfig.verticalConfig = state;
};

export const verticalConfigReducer = (state = defaultVerticalConfigState, { type, payload }: any) => {
  let nState = state;
  switch (type) {
    case SET_VERTICAL_CONFIG:
      nState = {
        ...state,
        ...payload
      };
      break;

    case SET_SELECTED_SUB_VERTICAL:
      nState = {
        ...state,
        verticalConfig: {
          ...state.verticalConfig,
          ...payload
        }
      };
      break;

    case SET_COMPANY_NAME: {
      nState = {
        ...state,
        verticalConfig: {
          ...state.verticalConfig,
          companyName: payload
        }
      };
      break;
    }

    case SET_USECASE_TO_KPIS: {
      nState = {
        ...state,
        verticalConfig: {
          ...state.verticalConfig,
          useCaseToKpis: payload
        }
      };
      break;
    }

    case SET_SELECTED_USE_CASE: {
      nState = {
        ...state,
        verticalConfig: {
          ...state.verticalConfig,
          useCaseId: payload
        }
      };
      break;
    }

    case SET_SCHEMA_FETCHING: {
      nState = {
        ...state,
        isSchemaFetching: payload
      };
      break;
    }

    case UPDATE_USE_CASE_MAP_WITH_SCHEMA: {
      const dataQueryConfigsMap = payload as Record<string, UseCaseConfig>;

      const updateUseCaseSchema = (useCase: VerticalUseCaseSummary) => {
        const useCaseId = useCase.id;
        const useCaseConfig = dataQueryConfigsMap[useCaseId] || {};
        Object.assign(useCase, useCaseConfig);
      };

      state.verticalConfig.useCaseDTOs.forEach(updateUseCaseSchema);
      state.verticalConfig.memberUseCaseDTOs.forEach(updateUseCaseSchema);

      nState = {
        ...state,
        isSchemaFetching: false,
        useCaseSchemaExists: true,
        verticalConfig: {
          ...state.verticalConfig,
          useCaseDTOs: clone(state.verticalConfig.useCaseDTOs),
          memberUseCaseDTOs: clone(state.verticalConfig.memberUseCaseDTOs)
        }
      };
      break;
    }

    case ADD_USE_CASE_SCHEMA: {
      const { companyName, subVerticalId, useCase, verticalId } = payload as AddUseCasePayload;

      const color = getUseCaseColor(state.verticalConfig.useCaseDTOs.length + 1);

      nState = {
        ...state,
        verticalConfig: {
          ...state.verticalConfig,
          memberUseCaseDTOs: [
            ...state.verticalConfig.memberUseCaseDTOs,
            {
              ...useCase,
              uiVerticalInfo: {
                companyName,
                subVerticalId,
                verticalId
              },
              color
            }
          ],
          useCaseDTOs: [
            ...state.verticalConfig.useCaseDTOs,
            {
              ...useCase,
              uiVerticalInfo: {
                companyName,
                subVerticalId,
                verticalId
              },
              color
            }
          ]
        }
      };

      break;
    }

    case DELETE_USE_CASE_SCHEMA: {
      const useCaseId = payload as string;

      const nUseCaseDTOs = state.verticalConfig.useCaseDTOs.filter(useCase => useCase.id !== useCaseId);
      const nMemberUseCaseDTOs = state.verticalConfig.memberUseCaseDTOs.filter(useCase => useCase.id !== useCaseId);

      nState = {
        ...state,
        verticalConfig: {
          ...state.verticalConfig,
          useCaseDTOs: nUseCaseDTOs,
          memberUseCaseDTOs: nMemberUseCaseDTOs
        }
      };

      break;
    }

    case UPDATE_USE_CASE_SCHEMA: {
      const { useCase: useCaseSchema, incremental } = payload as UpdateUseCasePayload;

      const checkAndUpdateUseCaseSchema = (useCase: VerticalUseCaseSummary) => {
        if (useCase.id === useCaseSchema.id) {
          if (incremental) {
            const nonUpdatedDQCs = useCase.dataQueryConfigs.filter(
              dqc => !useCaseSchema.dataQueryConfigs.find(dqc2 => dqc.id === dqc2.id)
            );
            useCase.dataQueryConfigs = [...nonUpdatedDQCs, ...useCaseSchema.dataQueryConfigs];
          } else {
            Object.assign(useCase, useCaseSchema);
          }
        }
      };

      state.verticalConfig.useCaseDTOs.forEach(checkAndUpdateUseCaseSchema);
      state.verticalConfig.memberUseCaseDTOs.forEach(checkAndUpdateUseCaseSchema);
      state.verticalConfig.useCaseToKpis[useCaseSchema.id] = { kpis: useCaseSchema.kpis };

      nState = {
        ...nState,
        verticalConfig: {
          ...state.verticalConfig,
          useCaseDTOs: clone(state.verticalConfig.useCaseDTOs),
          memberUseCaseDTOs: clone(state.verticalConfig.memberUseCaseDTOs),
          useCaseToKpis: clone(state.verticalConfig.useCaseToKpis)
        }
      };
      break;
    }

    case DELETE_OP10ZE_FROM_USE_CASE: {
      const { useCaseId, dqcId, opConfigId } = payload as DeleteOp10zePayload;

      const checkAndUpdateUseCaseSchema = (useCase: VerticalUseCaseSummary) => {
        if (useCase.id === useCaseId) {
          useCase.dataQueryConfigs = [...useCase.dataQueryConfigs];
          useCase.dataQueryConfigs.forEach(dqc => {
            if (dqc.id === dqcId) {
              dqc.relatedOpConfigs = dqc.relatedOpConfigs.filter(relConfig => {
                const { bizDataOperationalize } = relConfig;
                return bizDataOperationalize?.opConfigId !== opConfigId;
              });
            }
          });
        }
      };

      state.verticalConfig.useCaseDTOs.forEach(checkAndUpdateUseCaseSchema);
      state.verticalConfig.memberUseCaseDTOs.forEach(checkAndUpdateUseCaseSchema);

      nState = {
        ...nState,
        verticalConfig: {
          ...state.verticalConfig,
          useCaseDTOs: clone(state.verticalConfig.useCaseDTOs),
          memberUseCaseDTOs: clone(state.verticalConfig.memberUseCaseDTOs)
        }
      };
      break;
    }

    case UPDATE_OP10ZE_OF_USE_CASE: {
      const { useCaseId, dqcId, opConfigId, opCreationConfig } = payload as UpdateOp10zePayload;

      const checkAndUpdateUseCaseSchema = (useCase: VerticalUseCaseSummary) => {
        if (useCase.id === useCaseId) {
          useCase.dataQueryConfigs = [...useCase.dataQueryConfigs];
          useCase.dataQueryConfigs.forEach(dqc => {
            if (dqc.id === dqcId) {
              dqc.relatedOpConfigs.forEach(relConfig => {
                const { bizDataOperationalize } = relConfig;
                if (bizDataOperationalize?.opConfigId === opConfigId) {
                  relConfig.name = opCreationConfig.name;
                }
              });
            }
          });
        }
      };

      state.verticalConfig.useCaseDTOs.forEach(checkAndUpdateUseCaseSchema);
      state.verticalConfig.memberUseCaseDTOs.forEach(checkAndUpdateUseCaseSchema);

      nState = {
        ...nState,
        verticalConfig: {
          ...state.verticalConfig,
          useCaseDTOs: clone(state.verticalConfig.useCaseDTOs),
          memberUseCaseDTOs: clone(state.verticalConfig.memberUseCaseDTOs)
        }
      };
      break;
    }

    case ADD_WIDGET_TO_USE_CASE: {
      const { useCaseId, dataQueryConfig } = payload as AddWidgetToUseCasePayload;

      const updateUseCaseSchema = (useCase: VerticalUseCaseSummary) => {
        if (useCase.id === useCaseId) {
          useCase.dataQueryConfigs = [...useCase.dataQueryConfigs, dataQueryConfig];
        }
      };

      state.verticalConfig.useCaseDTOs.forEach(updateUseCaseSchema);
      state.verticalConfig.memberUseCaseDTOs.forEach(updateUseCaseSchema);

      nState = {
        ...state,
        isSchemaFetching: false,
        useCaseSchemaExists: true,
        verticalConfig: {
          ...state.verticalConfig,
          useCaseDTOs: clone(state.verticalConfig.useCaseDTOs),
          memberUseCaseDTOs: clone(state.verticalConfig.memberUseCaseDTOs)
        }
      };
      break;
    }

    case ADD_TO_MY_USE_CASES: {
      const { memberSettings, useCaseId } = payload as AddToMyUseCasesPayload;

      const matchingUseCase = state.verticalConfig.useCaseDTOs.find(useCase => useCase.id === useCaseId);

      if (matchingUseCase) {
        matchingUseCase.memberSettings = memberSettings;

        nState = {
          ...state,
          verticalConfig: {
            ...state.verticalConfig,
            useCaseDTOs: clone(state.verticalConfig.useCaseDTOs),
            memberUseCaseDTOs: [...state.verticalConfig.memberUseCaseDTOs, matchingUseCase]
          }
        };
      }

      break;
    }

    case REMOVE_FROM_MY_USE_CASES: {
      const { memberSettings, useCaseId } = payload as RemoveFromMyUseCasesPayload;

      const matchingUseCase = state.verticalConfig.useCaseDTOs.find(useCase => useCase.id === useCaseId);

      if (matchingUseCase) {
        matchingUseCase.memberSettings = memberSettings;
        nState = {
          ...state,
          verticalConfig: {
            ...state.verticalConfig,
            useCaseDTOs: clone(state.verticalConfig.useCaseDTOs),
            memberUseCaseDTOs: state.verticalConfig.memberUseCaseDTOs.filter(useCase => useCase.id !== useCaseId)
          }
        };
      }

      break;
    }

    case UPDATE_USE_CASE_UNDERLYING_INFO: {
      const useCaseToUnderlyingInfo = payload as Record<string, VerticalUseCaseUnderlyingInfo>;

      nState = {
        ...state,
        verticalConfig: {
          ...state.verticalConfig,
          useCaseToUnderlyingInfo: {
            ...state.verticalConfig.useCaseToUnderlyingInfo,
            ...useCaseToUnderlyingInfo
          }
        }
      };
      break;
    }

    default:
      break;
  }

  updateAppConfig(nState?.verticalConfig);

  return nState;
};

type AddUseCasePayload = {
  verticalId: string;
  subVerticalId: string;
  companyName: string;
  useCase: UseCaseSummaryWithSchema;
};

type UpdateUseCasePayload = {
  useCase: UseCaseConfig;
  incremental: boolean;
};

type AddToMyUseCasesPayload = {
  memberSettings: MemberSettings;
  useCaseId: string;
};

type RemoveFromMyUseCasesPayload = {
  memberSettings: MemberSettings;
  useCaseId: string;
};

type DeleteOp10zePayload = {
  useCaseId: string;
  dqcId: string;
  opConfigId: string;
};

type UpdateOp10zePayload = {
  useCaseId: string;
  dqcId: string;
  opConfigId: string;
  opCreationConfig: OpCreationConfig;
};

type AddWidgetToUseCasePayload = {
  useCaseId: string;
  dataQueryConfig: UseCaseDataQueryConfig;
};

type SetSubVerticalPayload = {
  verticalId: string;
  subVerticalId: string;
};
