import { useCallback } from "react";
import { logger, useFetchDataStatus } from "../../../../core";
import exploreApiService from "../ExploreApiService";
import { WidgetConfigDTO, WidgetResponseDTO } from "../types";

export const useFetchWidgetResponseDTO = () => {
  const { data, error, isError, isFetching, isSuccess, setStatus } = useFetchDataStatus<WidgetResponseDTO, string>({
    data: null,
    isFetching: true
  });

  const fetchWidgetResponseDTO = useCallback(
    async (widgetId: string, entityType: string, entityId: string, widgetConfig?: WidgetConfigDTO) => {
      setStatus(null, true, false, false, null);

      if (!widgetId && !widgetConfig) {
        setStatus(null as any, false, false, true, "Widget ID and Config cannot be empty");
      } else if (!entityType && !entityId) {
        setStatus(null, false, false, true, "Both entity type and ID cannot be empty");
      } else {
        try {
          if (widgetId) {
            const {
              data: widgetResponseDto,
              error,
              message
            } = await exploreApiService.getWidgetConfig(entityId, entityType, widgetId);
            setStatus(widgetResponseDto, false, !error, error, message);
          } else {
            const {
              data: querySchema = [],
              error,
              message
            } = await exploreApiService.getQuerySchemaForDraftWidgetConfig(widgetConfig);

            const widgetResponseDto: WidgetResponseDTO = {
              widgetConfig,
              widgetId,
              querySchema: { querySchema },
              version: 1
            };
            setStatus(widgetResponseDto, false, !error, error, message);
          }
        } catch (e) {
          const message = `Failed to fetch widgetConfig and Scheme for ${widgetId} ${entityType}`;
          logger.error("useFetchWidgetConfigAndQuerySchema", message, e);
          setStatus(null, false, false, true, message);
        }
      }
    },
    [setStatus]
  );

  return {
    data,
    error,
    isError,
    isFetching,
    isSuccess,
    fetchWidgetResponseDTO
  };
};
