import { LogicalOperation, NumberOperation, StringOperation } from "../../../core/data/types/BaseOperatorTypes";
import { Entity } from "../../../core";
import { FieldPrimType, FieldSubType } from "../../../core/data/types/DataTypes";
import {
  Stream,
  SourceFieldMapping,
  ConnectorPreviewData,
  PreviewDataObj,
  SyncMode,
  PrimaryKey,
  Cluster,
  UiBizfieldDetailsWithType,
  ConnectorStatus,
  ConfiguredConnectorStream,
  AdvancedConfiguration,
  ConfiguredConnectorStreamListV2,
  ConnectionIds
} from "../entity-mapping";
import { SelectorGridFieldDef } from "../../../../features/configuration/integrations/user-service-mapping2/fields-modal/types";

export type SourceType = "BUSINESS_SYSTEM" | "EVENT" | "KNOWLEDGE_BASE";

export type SourceTypeDef = {
  sourceType: SourceType;
  sourceTypeId: string;
  displayName: string;
  icon: string;
  categories: string[];
  additionalProperties?: SourceAdditionalProps;
  documentationUrl?: string;
};

type SourceAdditionalProps = {
  isCloneEnabled: string;
  isOnPremEnabled: string;
  iconShape?: string;
};

export type SourceListResponse = {
  sourceTypes: SourceTypeDef[];
};

export type SourceTypesRequest = {
  sourceType: SourceType;
  category: string;
};

export type ConfiguredSources = {
  sources: SourceSummary[];
};

export type MetricSummary = Record<string, any>;

export enum CheckConnectionResponseStatus {
  SUCCEEDED,
  FAILED
}

export type CheckConnectionResponse = {
  status: CheckConnectionResponseStatus;
  message: string;
};

export type DiscoverSourceResponse = Record<string, any>;

export type PreviewDataRequest = {
  connectorSourceId: string;
  previewFilters: SourceFilter[];
  limit: number;
};

export type PreviewDataResponse = {
  previewFieldData: PreviewFieldData;
  allFilterOperations: AllFilterOperations;
};

export type PreviewFieldData = {
  sourcefield: SourceField;
  data: string;
};

export type SourceField = {
  name: string;
  jsonPath: string;
  isObject: boolean;
  isArray: boolean;
  type: FieldPrimType;
  //if its an array or object
  fields: Record<string, SourceField>;
};

export type AllFilterOperations = Record<string, any>;

export type SourceFilter = {
  predicateTree: SourceFilterPredicateTree;
};

export type SourceFilterPredicateTree = {
  evalExpression?: string;
  predicates: SourceTreePredicate[];
  operator?: "AND" | "OR";
};

export type SourceTreePredicate = {
  fieldMapping: SourceFieldMapping;
  operation: SourceTreePredicateOperation;
  fieldValue: string;
  //value still here to support old ones
  value?: SourceTreePredicateVal;
  label?: string;
};

export type SourceTreePredicateOperation = {
  // Only one of these is used
  stringFilterOperation?: StringOperation;
  numberFilterOperation?: NumberOperation;
  comparisonOperation?: LogicalOperation;
};

export type SourceTreePredicateVal = {
  stringVal?: string;
  longVal?: number;
  doubleVal?: number;
  booleanVal?: boolean;
};

export type UserServiceMappingRequest = {
  rules: UserServiceMappingRule;
};

export type UserServiceMappingRule = {
  ruleId: string;
  ruleName: string;
  priority: number;
  filters: SourceFilter[];
  userServiceNaming: UserServiceNamingRule;
  userServiceFields: UserServiceFieldsRule;
  ignoredUserServices: string[];
  userServiceToDisplayName: Record<string, string>;
  userServiceToIcons?: Record<string, string>;
};

export type UserServiceNamingRule = {
  concatString: string;
  explicitName: string;
  namingFilters: UserServiceNamingFilter[];
};

export type UserServiceNamingFilter = {
  userServiceNameMapping: SourceFieldMapping;
  namingOperation: UserServiceNamingOperationType;
  operationValue: string;
};

export type UserServiceNamingOperationType = "URL_SEGMENTS" | "JSON_SELECTOR" | "USE_COLUMN_NAME";

export type PredefinedFieldType = "groupingID" | "eventID" | "duration" | "hasError" | "startTimeMicros" | string;

export type UserServiceFieldsRule = {
  commonFields: UserServiceFieldDef[];
  userServiceFields: Record<string, UserServiceFieldsList>;
};

export type UserServiceFieldsList = {
  userServiceField: UserServiceFieldDef[];
};

export type FieldToEntityMapping = {
  entityType: string;
  fields: SpanEntityField[];
};

export type UserServiceFieldDef = {
  fieldName: string;
  fieldMapping?: SourceFieldMapping;
  datatype?: FieldPrimType;

  category: FieldTypeCategory;
  predefinedFieldType?: PredefinedFieldType;
  //displayName is deprecated but still exists in the older rules
  //displayName should be predefinedFieldType now
  displayName?: string;
  customObjectMapping?: CustomObjectMapping;
  complexObjectType?: string;
  isVirtual?: boolean;
  entityType?: string;
  isVirtualEntity?: boolean;
  fieldToEntityMapping?: FieldToEntityMapping;
  subtype: FieldSubType;
  description?: string;
};

export enum FieldTypeCategory {
  BUSINESS = "BUSINESS",
  DIAGNOSTIC = "DIAGNOSTIC",
  RECOMMENDED = "RECOMMENDED"
}

export type CustomObjectMapping = {
  propertyMap: Record<string, UserServiceFieldDef>;
};

export type SpanEntityField = {
  field: SpanField;
  entityIdentifierKey: string;
};

export type SpanField = {
  spanAttributeName: string;
  dataType?: FieldPrimType;
  ignoreIfNull?: boolean;
  jsonPath?: string;
  customObjectFieldName?: string;
};

export type UserServicePreviewRequest = {
  previewDataRequest: PreviewDataRequest;
  userServiceMappingRule: UserServiceNamingRule;
};

export type BicycleEventPreviewRequest = {
  previewDataRequest: PreviewDataRequest;
  userServiceMappingRule: UserServiceMappingRule;
};

export type UserServiceNameResponse = {
  // Map of user service name to source schema
  userServiceToPreviewData: Record<string, PreviewDataObj>;
  commonPreviewData: PreviewDataObj;
};

//should be moved to schema

export type RuleSummary = {
  id: string;
  name: string;
  userServices: string[];
};

export type GetRulesAPIResponse = {
  rules: RuleSummary[];
};

export type UserServiceMappingRulesSummary = {
  ruleId: string;
  ruleName: string;
  priority: number;
  ruleMetrics: EventsSourceMetricSummary[];
  eventsMatchedPerUserService: Record<string, UserServiceSummary>;
};

export type UserServiceSummary = {
  eventsMatched: number;
  userServiceEntity: Entity;
};

export interface EventPreviewData extends ConnectorPreviewData {}

export enum HealthStatus {
  UNKNOWN = "UNKNOWN",
  HEALTHY = "HEALTHY",
  UNHEALTHY = "UNHEALTHY"
}

export interface MappingHealthStatus {
  status: HealthStatus;
  errorCode: string;
  errorMessage: string;
}

export type SourceSummary = {
  configuredConnectorStreamId: string;
  name: string;
  enabled: boolean;
  sourceTypeId: string;
  metricSummaries: EventsSourceMetricSummary[];
  healthStatus: MappingHealthStatus;
};

export type EventsSourceMetricSummary = {
  metricName: string;
  metricDisplayName: string;
  metricValue: number;
};

export type SaveEventSourceConfigResponse = {
  savedConnectorStream: SaveSourceConfig;
  error: string[];
};

export type SaveSourceConfig = {
  name?: string;
  configuredConnection?: SaveSourceConfigureConnection;
  configuredStream?: ConfiguredStream;
  configuredConnectorStreamId?: string;
  connectionId?: string;
  sourceType?: SourceType;
  additionalProperties?: Record<string, string>;
};

export type SaveSourceConfigureConnection = {
  name: string;
  sourceTypeId: string;
  connectionConfiguration: string;
  advanceConfigurations: AdvancedConfiguration[];
  connectionId?: string;
  clusterContext?: Cluster;
  isLite?: boolean;
};

export type ConfiguredStream = {
  stream: Stream;
  syncMode: SyncMode;
  cursorField: string[];
  primaryKey: PrimaryKey[];
  additionalProperties: Record<string, string>;
};

export type ClonedMappingRulesResponse = {
  message: string;
  status: "SUCCESS" | "FAILURE";
};

export type UnsavedUserServiceFieldsList = {
  userServiceField: SelectorGridFieldDef[];
};

export type unSavedChanges = {
  commonFields: SelectorGridFieldDef[];
  userServiceFields: Record<string, UnsavedUserServiceFieldsList>;
};

export interface UserServiceMappingContextState {
  userServiceMappingRule: UserServiceMappingRule;
  unsavedChanges: unSavedChanges;
  fieldNameToBizFieldDetails: Map<string, UiBizfieldDetailsWithType>;
}

export type SaveEntityConfig = {
  connectionId: string;
  stream: Stream;
  sourceType: SourceType;
  streamIdForConnection: string;
  syncMode: SyncMode;
  cursorField: string[];
};

export type EventConnectorStreamDetail = {
  streamDetail: ConfiguredConnectorStream;
  totalEventsIngested: number;
  totalEventsMatched: number;
  totalEventsUnmatched: number;
  connectorStatus: ConnectorStatus;
  schedule: string;
};

export type EventConnectorStatusResponse = {
  streamDetail: Record<string, EventConnectorStreamDetail>;
};

export interface IntegrationsResponse {
  integrationTypes: IntegrationTypeInfo[];
}

export interface IntegrationTypeInfo {
  name: string;
  type: SourceType;
  description: string;
  icon: string;
  sourceTypes: SourceTypeDef[];
}

export type SaveConnectionResponse = {
  traceInfo: {
    traceId: string;
  };
  savedConnectorStreamList: ConfiguredConnectorStreamListV2;
  error: string[];
  runId: string;
};

export type SaveConnectionRequest = {
  traceInfo: {
    traceId: string;
  };
  companyName: string;
  inputContext: string;
  connectorStreamList: ConfiguredConnectorStreamListV2;
  connectionIds?: ConnectionIds;
};
