import {
  cloneDeep,
  first,
  forEach,
  groupBy,
  intersection,
  isArray,
  isEmpty,
  isEqual,
  omit,
  pick,
  uniq,
  uniqBy,
  values,
  zipObject
} from "lodash";
import qs from "query-string";
import { IncSelectOption } from "@inception/ui";
import { DataType, generateId, logger } from "../../../../core";
import {
  BizDataQuery,
  BizEntityMetricConfigDefinition,
  BuildingBlockConfig,
  CompareConfig,
  CompareConfigType,
  DataDefinition,
  EntityMetricConfigDefinition,
  ExpressionMetricConfig,
  ExpressionMetricConfigDefinition,
  MetricAndSliceRecord,
  MetricConfigDefinition,
  MetricDefinition,
  MetricUserServiceFilters,
  OverTagAggregators,
  OverTimeAggregators,
  SelectorSpec,
  SelectorTag,
  Slice,
  SliceSet,
  SliceSpec,
  TagFilter,
  UserServiceField,
  UserServiceFieldMetricConfigDefinition,
  UserServiceFieldSlice,
  UserServiceFieldSliceSet,
  UserServiceFieldWithMeta,
  UserServiceFilterExpression,
  UserServiceFilterExpressionTree,
  UserServiceFilterList,
  UserServiceMetricConfigDefinition,
  WidgetConfig,
  WidgetConfigDTO,
  WidgetQuerySchema,
  WidgetResponseDTO
} from "../types";
import { NAME_TAG, QUANTILE_TAG, shouldExcludeTag } from "../../../../core/utils";
import { FieldPickerUtils } from "../../../../utils/FieldPickerUtils";
import {
  convertToSliceSet,
  getDefaultWidgetResponseDto,
  getDtoFromWidgetConfig,
  getWidgetConfigFromDto,
  isSystemCreatedMetric
} from "../../../../utils/ExploreUtils";
import { ENTITY_TAG } from "../../../../utils/MetricNameUtils";
import { BizIdProps } from "../../operationalise";
import { isEntity, isJsonString } from "../../../../utils";

export const newWidgetConfigDto = (
  entityType: string,
  entityId: string,
  entityName: string = null,
  entityTypeName: string = null
): WidgetResponseDTO => getDefaultWidgetResponseDto(entityType, entityId, entityName, entityTypeName);

export class WidgetConfigUtils {
  constructor() {
    logger.debug("WidgetConfigUtils", "Util class");
  }

  static extractSliceSpecsFromExpressionMetric(expressionMetricConfig: ExpressionMetricConfig): SliceSpec[] {
    const sliceSpecArr: SliceSpec[] = [];
    if (expressionMetricConfig && expressionMetricConfig.expression) {
      const { expression } = expressionMetricConfig;
      const expMetricConfigs = [
        expression.leftExpr?.expressionMetricConfig,
        expression.rightExpr?.expressionMetricConfig
      ].filter(Boolean);
      expMetricConfigs.forEach(exp =>
        sliceSpecArr.push(...WidgetConfigUtils.extractSliceSpecsFromExpressionMetric(exp))
      );
      return sliceSpecArr;
    } else if (expressionMetricConfig && expressionMetricConfig.sliceSpec) {
      sliceSpecArr.push(expressionMetricConfig.sliceSpec);
      return sliceSpecArr;
    } else {
      return sliceSpecArr;
    }
  }

  static getMetricSliceSetRecord(
    metrics: Record<string, MetricDefinition>,
    widgetQuerySchemas: WidgetQuerySchema[],
    options?: {
      handleUnSavedExpSubMetrics?: boolean;
    }
  ): MetricAndSliceRecord[] {
    const records: MetricAndSliceRecord[] = [];

    const { handleUnSavedExpSubMetrics = false } = options || {};
    if (isEmpty(metrics) || isEmpty(widgetQuerySchemas)) {
      return records;
    }

    const metricIdsToOmit: string[] = [];
    widgetQuerySchemas.forEach(querySchema => {
      const { componentSourceFields = {}, metricId } = querySchema;

      if (metrics[metricId]?.sourceType === "expression") {
        const componentMetricIds = Object.keys(componentSourceFields);
        metricIdsToOmit.push(...componentMetricIds);
      }
    });

    values(metrics).forEach(metric => {
      if (!metric || metricIdsToOmit.includes(metric.id)) {
        return;
      }

      const ss = WidgetConfigUtils.getSliceSetFromMetricDefinition2(metric);

      if (isArray(ss)) {
        (ss as UserServiceFieldSliceSet[]).forEach(s => {
          const querySchema = WidgetConfigUtils.getMatchingQuerySchema(
            widgetQuerySchemas,
            metric.id,
            WidgetConfigUtils.getTagNamesFromSliceSet(s as UserServiceFieldSliceSet)
          );
          const compareConfigs = Object.values(metric.operationalizeConfig?.compareConfigs || {});
          const filtered = compareConfigs.filter(cc => WidgetConfigUtils.compareSliceSets(cc.usfSlices, s));
          records.push(constructMSR(metric, querySchema, s, filtered));
        });
      } else if (metric.sourceType === "expression") {
        const querySchema = widgetQuerySchemas.find(x => x.metricId === metric.id);
        const compareConfigs = Object.values(metric.operationalizeConfig?.compareConfigs || {});
        records.push(constructMSR(metric, querySchema, null, compareConfigs));
        if (handleUnSavedExpSubMetrics) {
          const sliceSpecs = WidgetConfigUtils.extractSliceSpecsFromExpressionMetric(metric.expressionMetricConfig);
          uniqBy(sliceSpecs, ss => ss.metricId).forEach(sSpec => {
            const unSavedMetricDef = metrics[sSpec.metricId];
            const querySchema = widgetQuerySchemas.find(x => x.metricId === unSavedMetricDef.id);
            if (unSavedMetricDef.doNotSave && unSavedMetricDef.sourceType === "userServiceField") {
              const sliceSet = unSavedMetricDef.userServiceFieldMetricConfig.sliceSets.map(usfSlice =>
                convertToSliceSet(usfSlice)
              );
              records.push(constructMSR(unSavedMetricDef, querySchema, null, [], sliceSet[0]));
            }
          });
        }
      } else if (metric.sourceType === "entity") {
        const compareConfigs = Object.values(metric.operationalizeConfig?.compareConfigs || {});
        records.push(constructMSR(metric, widgetQuerySchemas[0], null, compareConfigs));
      } else {
        const querySchema = WidgetConfigUtils.getMatchingQuerySchema(
          widgetQuerySchemas,
          metric.id,
          WidgetConfigUtils.getTagNamesFromSliceSet(
            ss as SliceSet,
            WidgetConfigUtils.checkIfBizMetricIsAggregated(metric)
          )
        );
        const compareConfigs = Object.values(metric.operationalizeConfig?.compareConfigs || {});
        records.push(constructMSR(metric, querySchema, null, compareConfigs));
      }
    });
    return records;
  }

  static getCompareTypeDisplayName(compareType: CompareConfigType): string {
    if (CompareConfigType.peer === compareType) {
      return "Peer comparision";
    }

    if (CompareConfigType.timeshift === compareType) {
      return "Time shift comparision";
    }

    if (CompareConfigType.baseline === compareType) {
      return "Baseline comparision";
    }

    return "Comparision";
  }

  static getMetricDefinition(widgetConfig: WidgetConfigDTO | WidgetConfig, metricId: string) {
    return widgetConfig?.dataDefinition.metrics[metricId];
  }

  static getTagNamesFromSliceSet(sliceSet: SliceSet | UserServiceFieldSliceSet, skipEntityTag = false) {
    const slices = (sliceSet.slices || []) as Array<Slice | UserServiceFieldSlice>;
    if (skipEntityTag) {
      return slices
        .map(x => x.tagName)
        .filter(x => x !== ENTITY_TAG)
        .sort();
    }
    return slices.map(x => x.tagName).sort();
  }

  static getCompareConfig(widgetConfig: WidgetConfigDTO, metricId: string, compareId: string) {
    return this.getMetricDefinition(widgetConfig, metricId)?.operationalizeConfig?.compareConfigs?.[compareId];
  }

  static getActionConfig(widgetConfig: WidgetConfigDTO, metricId: string, compareId: string, actionId: string) {
    return this.getCompareConfig(widgetConfig, metricId, compareId)?.actionConfigs[actionId];
  }

  static checkIfSliceSetMatches(
    sliceSet: SliceSet | UserServiceFieldSliceSet,
    tagNames: string[],
    skipEntityTag = false,
    allowPartialMatch = false
  ) {
    let fTagNames = [];
    let sliceTagNames = [];
    if (skipEntityTag) {
      fTagNames = tagNames.filter(tag => this.shouldIncludeTag(tag) && tag !== ENTITY_TAG).sort();
      sliceTagNames = (sliceSet.slices as any[])
        .map(s => s.tagName)
        .filter(x => x !== ENTITY_TAG)
        .sort();
    } else {
      fTagNames = tagNames.filter(tag => this.shouldIncludeTag(tag)).sort();
      sliceTagNames = (sliceSet.slices as any[]).map(s => s.tagName).sort();
    }

    if (allowPartialMatch) {
      const commonTags = intersection(sliceTagNames, fTagNames);
      return Boolean(commonTags.length);
    }

    return isEqual(sliceTagNames, fTagNames);
  }

  static getSliceSetTagsMatches(sliceSet: SliceSet | UserServiceFieldSliceSet, tagNames: string[]) {
    const fTagNames = tagNames.filter(tag => this.shouldIncludeTag(tag)).sort();
    const sliceTagNames = (sliceSet.slices as any[]).map(s => s.tagName).sort();
    return intersection(sliceTagNames, fTagNames);
  }

  /**
   * Given two slice sets , Compare one with other based on tagName and give result
   * Does Safe Null checks for slice set being undefined or SliceSet being undefined
   * @param sliceSet1 first compare set
   * @param sliceSet2 second compare set
   * @param skipEntityTag
   */
  static compareSliceSets(
    sliceSet1: SliceSet | UserServiceFieldSliceSet,
    sliceSet2: SliceSet | UserServiceFieldSliceSet,
    skipEntityTag = false
  ): boolean {
    // Null check as a fail safe
    if (!sliceSet1 || !sliceSet1?.slices || !sliceSet2 || !sliceSet2?.slices) {
      return false;
    }
    return this.compareSlices(sliceSet1.slices, sliceSet2.slices, skipEntityTag);
  }

  /**
   * Given two slice sets , Compare one with other based on tagName and give result
   * Does Safe Null checks for slice set being undefined or Slices being undefined
   * @param slices1 first compare set
   * @param slices2 second compare set
   * @param skipEntityTag
   */
  static compareSlices(
    slices1: Array<Slice | UserServiceFieldSlice>,
    slices2: Array<Slice | UserServiceFieldSlice>,
    skipEntityTag = false
  ): boolean {
    // Null check as a fail safe
    if (!slices1 || !slices2) {
      return false;
    }
    const sliceTagNames1 = this.getTagNamesFromSlices(slices1, skipEntityTag);
    const sliceTagNames2 = this.getTagNamesFromSlices(slices2, skipEntityTag);
    return isEqual(sliceTagNames1, sliceTagNames2);
  }

  static getTagNamesFromSlices(slices: Array<Slice | UserServiceFieldSlice>, skipEntityTag = false) {
    if (skipEntityTag) {
      return slices
        .map(x => x.tagName)
        .filter(tag => this.shouldIncludeTag(tag) && tag !== ENTITY_TAG)
        .sort();
    }
    return slices
      .map(x => x.tagName)
      .filter(tag => this.shouldIncludeTag(tag))
      .sort();
  }

  static shouldIncludeTag(tag: string) {
    // This will check for tags like _apptuit_** or _inception_**
    const isExcludeTag = shouldExcludeTag(tag);
    const isNameTag = tag === NAME_TAG;
    const isQuantileTag = tag === QUANTILE_TAG;
    return !isExcludeTag && !isNameTag && !isQuantileTag;
  }

  static getMatchingQuerySchema(
    querySchemas: WidgetQuerySchema[],
    metricId: string,
    sliceTagNames: string[],
    allowPartialMatch = false
  ) {
    let matchingSchema: WidgetQuerySchema;
    if (metricId) {
      const metricSchemas = querySchemas.filter(q => q.metricId === metricId);
      if (sliceTagNames) {
        const sliceSchemas = metricSchemas.filter(q => {
          const { sliceSet } = q;
          return this.checkIfSliceSetMatches(sliceSet, sliceTagNames, false, allowPartialMatch);
        });
        matchingSchema = sliceSchemas[0];
      } else {
        matchingSchema = metricSchemas[0];
      }
    } else {
      matchingSchema = querySchemas[0];
    }
    return matchingSchema;
  }

  /**
   * This util returns all the metrics definitions that are to be shown in the UI in various places
   * like summary, operationalise. This util hides the aggregated system metrics that are added when
   * the user chooses to aggregate the entities away.
   */
  static getUserVisibleMetricDefs = (widgetConfig: WidgetConfigDTO) => {
    if (!widgetConfig) {
      return [];
    }

    const { dataDefinition } = widgetConfig;
    const { metrics = {} } = dataDefinition || {};
    const displayMetrics: MetricDefinition[] = [];
    Object.keys(metrics).forEach(metricId => {
      const mDef = metrics[metricId];
      const canDisplay = !mDef.doNotSave;
      if (canDisplay) {
        displayMetrics.push(mDef);
      }
    });

    return displayMetrics;
  };

  /**
   * This util returns all the metrics definitions that can be published.
   * This util hides the aggregated system metrics and biz metrics.
   * This is required to skip cohort agg metrics.
   */
  static getPublishableMetrics = (widgetConfig: WidgetConfigDTO) => {
    if (!widgetConfig) {
      return [];
    }

    const { dataDefinition } = widgetConfig;
    const { metrics = {} } = dataDefinition || {};
    const displayMetrics: MetricDefinition[] = [];
    Object.keys(metrics).forEach(metricId => {
      const mDef = metrics[metricId];
      const canDisplay = !mDef.doNotSave && !isSystemCreatedMetric(mDef) && mDef.sourceType !== "bizEntityMetric";
      if (canDisplay) {
        displayMetrics.push(mDef);
      }
    });

    return displayMetrics;
  };

  static getDisplaySlicesForSliceSet(ss: SliceSet | UserServiceFieldSliceSet) {
    const slices = (ss.slices || []) as Array<Slice | UserServiceFieldSlice>;
    return slices.filter(s => s.tagName !== ENTITY_TAG && !shouldExcludeTag(s.tagName));
  }

  static getMetricDefinitions(metricDefinition: MetricDefinition) {
    const usFieldMetricDef = metricDefinition as UserServiceFieldMetricConfigDefinition;
    const usMetricMetricDef = metricDefinition as UserServiceMetricConfigDefinition;
    const bizEntityMetricDef = metricDefinition as BizEntityMetricConfigDefinition;
    const expressionMetricDef = metricDefinition as ExpressionMetricConfigDefinition;
    const entityMetricDef = metricDefinition as EntityMetricConfigDefinition;

    return {
      usFieldMetricDef,
      usMetricMetricDef,
      bizEntityMetricDef,
      expressionMetricDef,
      entityMetricDef
    };
  }

  /**
   * @deprecated
   * Use getSliceSetFromMetricDefinition2
   */
  static getSliceSetFromMetricDefinition(metricDef: MetricDefinition): SliceSet | UserServiceFieldSliceSet {
    const { usFieldMetricDef, usMetricMetricDef, bizEntityMetricDef, expressionMetricDef } =
      this.getMetricDefinitions(metricDef);
    if (usFieldMetricDef?.userServiceFieldMetricConfig) {
      return usFieldMetricDef.userServiceFieldMetricConfig.sliceSets[0];
    } else if (usMetricMetricDef.userServiceMetricConfig) {
      return usMetricMetricDef.userServiceMetricConfig.sliceSet;
    } else if (bizEntityMetricDef.bizEntityMetricConfig) {
      return bizEntityMetricDef.bizEntityMetricConfig.sliceSet;
    } else if (expressionMetricDef.expressionMetricConfig) {
      return { slices: [] };
    } else {
      return { slices: [] };
    }
  }

  static getQuerySchema(
    metricDefs: Array<UserServiceFieldMetricConfigDefinition | BizEntityMetricConfigDefinition>,
    skipCohortMetrics: boolean
  ): WidgetQuerySchema[] {
    const querySchemas: WidgetQuerySchema[] = [];

    const getQuerySchema = (
      id: string,
      name: string,
      sliceSet: SliceSet,
      aggregator: OverTimeAggregators
    ): WidgetQuerySchema => ({
      metricId: id,
      metricName: name,
      sliceSet: sliceSet,
      resultKey: `_inception_${id}_ALL_${this.getTagNamesFromSliceSet(sliceSet)
        .filter(s => s !== ENTITY_TAG)
        .join("_")}`,
      defaultTagAgg: "avg",
      defaultTimeAgg: aggregator
    });

    metricDefs.forEach(mDef => {
      if (mDef.sourceType === "userServiceField") {
        const { sliceSets } = mDef.userServiceFieldMetricConfig;
        (sliceSets || []).forEach(sliceSet => {
          querySchemas.push(
            getQuerySchema(
              mDef.id,
              mDef.name,
              convertToSliceSet(sliceSet),
              mDef.userServiceFieldMetricConfig.aggregator
            )
          );
        });
      }
      if (
        mDef.sourceType === "bizEntityMetric" &&
        (mDef.labels.isAggMetric !== "true" || (!skipCohortMetrics && mDef.labels.isAggMetric === "true"))
      ) {
        querySchemas.push(
          getQuerySchema(
            mDef.id,
            mDef.name,
            convertToSliceSet(mDef.bizEntityMetricConfig.sliceSet),
            mDef.bizEntityMetricConfig.aggregator
          )
        );
      }
    });
    return querySchemas;
  }

  static getLinkedDashboardsForCompareConfig(compareConfig: CompareConfig) {
    const { props } = compareConfig || {};
    const { linkedDashboards } = props || {};
    return linkedDashboards?.setValue?.values || [];
  }

  static updateLinkedDashboardsForCompareConfig(compareConfig: CompareConfig, linkedDashboards: string[]) {
    const { props } = compareConfig;
    const { linkedDashboards: linkedDashboardsProp } = props || {};
    if (linkedDashboardsProp) {
      linkedDashboardsProp.setValue.values = linkedDashboards;
    } else {
      const nProps = {
        linkedDashboards: {
          setValue: {
            values: linkedDashboards
          }
        }
      };
      if (!props) {
        compareConfig.props = {
          ...nProps
        };
      } else {
        compareConfig.props = {
          ...props,
          ...nProps
        };
      }
    }
  }

  static checkIfBizMetricIsAggregated(metricDef: MetricDefinition) {
    return metricDef.sourceType === "bizEntityMetric" && !isEmpty(metricDef.bizEntityMetricConfig.aggregator);
  }

  static getEntityIdsFromTagFilters(tagFilters: TagFilter[]) {
    return getEntityIdsFromTagFilters(tagFilters);
  }

  static sanitizeString(str: string) {
    return str?.replace(/['"]+/g, "") || "";
  }

  static sanitizeAndFilterEntityIds(entityIds: string[]) {
    return entityIds.filter(value => isEntity(this.sanitizeString(value))).map(value => this.sanitizeString(value));
  }

  static getEntityIdsFromFilterExpressions(filtersExpressions: UserServiceFilterExpression[]) {
    const entityIds: string[] = [];
    getEntityIdsFromFilterExpressions(filtersExpressions, entityIds);

    return this.sanitizeAndFilterEntityIds(entityIds);
  }

  static getEntityIdsFromFilterTree(filterTree: UserServiceFilterExpressionTree) {
    const entityIds: string[] = [];
    getEntityIdsFromExpressionTree(filterTree, entityIds);

    return this.sanitizeAndFilterEntityIds(entityIds);
  }

  static getUsFilterExpressionTreeLabel(
    filterTree: UserServiceFilterExpressionTree,
    entityLookUp: Record<string, string> = {},
    level = 0
  ): string {
    if (!filterTree) {
      return null;
    }

    const { filterNodes, logicalOperator } = filterTree;

    const nodeFilters = (filterNodes || []).map(node => {
      const { expression, expressionTree } = node;

      if (expression) {
        return this.getUsFilterExpressionLabel(expression, entityLookUp);
      }

      if (expressionTree) {
        return this.getUsFilterExpressionTreeLabel(expressionTree, entityLookUp, level + 1);
      }

      return "";
    });

    let currentFiltersStr = nodeFilters.filter(Boolean).join(` ${logicalOperator} `);
    currentFiltersStr = level > 0 ? `( ${currentFiltersStr} )` : currentFiltersStr;

    return currentFiltersStr;
  }

  static getUsFilterExpressionLabel(
    filterExpression: UserServiceFilterExpression,
    entityLookUp: Record<string, string> = {},
    valueOnly = false
  ) {
    const filterFieldName = FieldPickerUtils.getUserServiceFieldLabel(filterExpression.field);
    let filterOperator = filterExpression.operator;
    const filterFieldValue = this.sanitizeString(filterExpression?.value);
    const filterFieldValues = filterExpression?.values?.map(x => this.sanitizeString(x));
    let lookUpVal = entityLookUp[filterFieldValue] ? entityLookUp[filterFieldValue] : filterFieldValue;
    const lookUpVals = (filterFieldValues || []).map(x => (entityLookUp[x] ? entityLookUp[x] : x));
    if (lookUpVals.length > 0) {
      return FieldPickerUtils.getLabelAndInfo(filterFieldName, filterOperator, lookUpVals)?.label;
    }

    if (filterOperator === "!=" && lookUpVal === "" && lookUpVals.length === 0) {
      filterOperator = "";
      lookUpVal = "exists";
    }

    if (filterOperator === "=" && lookUpVal === "" && lookUpVals.length === 0) {
      filterOperator = "";
      lookUpVal = "does not exist";
    }

    return valueOnly ? `${lookUpVal || lookUpVals}` : `${filterFieldName} ${filterOperator} ${lookUpVal || lookUpVals}`;
  }

  static getTagFilterExpressionLabel(tagFilter: TagFilter, entityLookUp?: Record<string, string>) {
    const tagVal = this.sanitizeString(tagFilter.tagValue);
    const tagVals = tagFilter.tagValues.map(tv => this.sanitizeString(tv));
    const lookUpVal = entityLookUp[tagVal] ? entityLookUp[tagVal] : tagVal;
    const lookUpVals = tagVals.map(x => (entityLookUp[x] ? entityLookUp[x] : x));
    return `${tagFilter.tagName} ${tagFilter.op} ${lookUpVal || lookUpVals}`;
  }

  static getFiltersLabelForNonExpressionMetricDef(metricDef: MetricDefinition, entityLookUp?: Record<string, string>) {
    const { bizEntityMetricDef, usFieldMetricDef } = WidgetConfigUtils.getMetricDefinitions(metricDef);
    if (bizEntityMetricDef?.bizEntityMetricConfig?.filter) {
      return bizEntityMetricDef.bizEntityMetricConfig.filter
        .map(filter => WidgetConfigUtils.getTagFilterExpressionLabel(filter, entityLookUp))
        .join(" (AND) ");
    } else if (usFieldMetricDef?.userServiceFieldMetricConfig?.eventFilters) {
      return WidgetConfigUtils.getFiltersLabelForUserServiceFilters(
        usFieldMetricDef.userServiceFieldMetricConfig.eventFilters,
        entityLookUp
      );
    }

    return "";
  }

  static getFiltersLabelForUserServiceFilters(
    userServiceFilterList: UserServiceFilterList,
    entityLookUp?: Record<string, string>
  ) {
    const { expressionTree, userServiceFilters } = userServiceFilterList || {};

    if (expressionTree) {
      return WidgetConfigUtils.getUsFilterExpressionTreeLabel(expressionTree, entityLookUp);
    } else if (userServiceFilters) {
      const filtersStrArr: string[] = [];
      userServiceFilters?.forEach(filter => {
        if (filter?.userServiceFilterExpressions) {
          const subFiltersArr: string[] = [];
          filter.userServiceFilterExpressions.forEach(filterExpression => {
            subFiltersArr.push(WidgetConfigUtils.getUsFilterExpressionLabel(filterExpression, entityLookUp));
          });

          filtersStrArr.push(subFiltersArr.join(" (AND) "));
        }
      });

      return filtersStrArr.join(" (OR) ");
    }

    return "";
  }

  static getFilterExpressionFromMetricDef(metricDef: MetricDefinition, entityLookUp?: Record<string, string>) {
    let filterExprs: string[] = [];
    if (metricDef.sourceType === "userServiceField") {
      const { eventFilters, filterExpressions: defFilterExpressions } = metricDef.userServiceFieldMetricConfig;
      const filterExpressions =
        defFilterExpressions || eventFilters?.userServiceFilters?.[0]?.userServiceFilterExpressions || [];
      filterExprs = filterExpressions.map(filter => this.getUsFilterExpressionLabel(filter, entityLookUp));
    } else if (metricDef.sourceType === "bizEntityMetric") {
      filterExprs = metricDef.bizEntityMetricConfig.filter.map(f => this.getTagFilterExpressionLabel(f, entityLookUp));
    } else if (metricDef.sourceType === "userServiceMetric") {
      filterExprs = metricDef.userServiceMetricConfig.filter.map(f =>
        this.getTagFilterExpressionLabel(f, entityLookUp)
      );
    }
    return filterExprs;
  }

  static getEntityIdsForNonExpressionMetricDef(metricDef: MetricDefinition) {
    let entityIds: string[] = [];

    const { bizEntityMetricDef, usFieldMetricDef } = WidgetConfigUtils.getMetricDefinitions(metricDef);
    if (bizEntityMetricDef?.bizEntityMetricConfig?.filter) {
      entityIds = WidgetConfigUtils.getEntityIdsFromTagFilters(bizEntityMetricDef.bizEntityMetricConfig.filter);
    } else if (usFieldMetricDef?.userServiceFieldMetricConfig?.eventFilters) {
      entityIds = WidgetConfigUtils.getEntityIdsForUserServiceFilters(
        usFieldMetricDef.userServiceFieldMetricConfig.eventFilters
      );
    }

    return uniq(entityIds);
  }

  static getEntityIdsForUserServiceFilters(userServiceFilterList: UserServiceFilterList) {
    let entityIds: string[] = [];
    const { expressionTree, userServiceFilters } = userServiceFilterList || {};

    if (expressionTree) {
      entityIds = WidgetConfigUtils.getEntityIdsFromFilterTree(expressionTree);
    } else if (userServiceFilters) {
      userServiceFilters?.forEach(filter => {
        if (filter?.userServiceFilterExpressions) {
          const subEntityIds = WidgetConfigUtils.getEntityIdsFromFilterExpressions(filter.userServiceFilterExpressions);
          entityIds.push(...subEntityIds);
        }
      });
    }

    return entityIds;
  }

  static convertUSFieldSliceSetToTagSlice(sliceSet: UserServiceFieldSliceSet): SliceSet {
    return {
      slices: (sliceSet?.slices || []).map(slice => {
        const { tagName, userServiceField } = slice;

        const { fieldName, entityField } = userServiceField;

        const { entityType = "" } = entityField || {};

        const fieldType = this.getUSFieldDataType(userServiceField);
        return {
          tagName,
          fieldName,
          fieldType,
          entityTypeName: entityType
        };
      })
    };
  }

  static getUSFieldDataType(userServiceField: UserServiceField) {
    const { dataType, entityField } = userServiceField;

    const { propType, propName = "" } = entityField || {};

    const entityFieldPropType = !propName ? dataType : ((propType || dataType) as DataType);
    return entityField ? entityFieldPropType : dataType;
  }

  static getMaxSlicesSliceSetByMetricId(querySchema: WidgetQuerySchema[]) {
    const metricQuerySchemas: Record<string, WidgetQuerySchema> = {};
    querySchema.forEach(qsEntry => {
      const { metricId, sliceSet } = qsEntry;

      const prevSliceSet = metricQuerySchemas[metricId]?.sliceSet;
      if (prevSliceSet) {
        const prevNumSlices = prevSliceSet?.slices?.length || 0;
        const currNumSlices = sliceSet.slices.length;

        if (currNumSlices > prevNumSlices) {
          metricQuerySchemas[metricId] = qsEntry;
        }
      } else {
        metricQuerySchemas[metricId] = qsEntry;
      }
    });

    return metricQuerySchemas;
  }

  static getImplicitSlice(usField: UserServiceField): UserServiceFieldSlice {
    const entityUsField = cloneDeep(usField);

    if (!entityUsField.bizEntityFieldName) {
      entityUsField.fieldName = "userService";
    }

    return {
      tagName: ENTITY_TAG,
      userServiceField: {
        ...entityUsField,
        dataType: "ENTITY"
      }
    };
  }

  static getUserServiceListFromUserServiceField(usField: UserServiceField): string[] {
    return usField.userServices.map(usf => usf.userServiceEntityId);
  }

  static getWidgetConfigDtoFromBizDataQuery(bizDataQuery: BizDataQuery) {
    const { id: widgetId, widgetConfig, buildingBlockConfig, metricUserServiceFilters } = bizDataQuery;

    const widgetConfigDto = buildingBlockConfig
      ? this.getWidgetConfigDtoFromBuildingBlockConfig(buildingBlockConfig, metricUserServiceFilters)
      : widgetConfig
        ? getDtoFromWidgetConfig(widgetConfig)
        : null;

    return {
      widgetConfigDto,
      widgetId
    };
  }

  /**
   * Extracts aggregation functions from all metrics in a BizDataQuery and returns them as a record
   * @param bizDataQuery The BizDataQuery to extract from
   * @returns A record with metric IDs as keys and their aggregation functions as values
   */
  static getMetricAggregationFunctionsRecord = (
    bizDataQuery: BizDataQuery
  ): Record<
    string,
    {
      overTagAggFunction?: OverTagAggregators;
      overTimeAggFunction?: OverTimeAggregators;
    }
  > => {
    if (!bizDataQuery) {
      return {};
    }

    const { widgetConfig, buildingBlockConfig, metricUserServiceFilters } = bizDataQuery;

    let widgetConfigDto: WidgetConfigDTO = null;

    if (widgetConfig) {
      widgetConfigDto = getDtoFromWidgetConfig(widgetConfig);
    } else if (buildingBlockConfig) {
      widgetConfigDto = WidgetConfigUtils.getWidgetConfigDtoFromBuildingBlockConfig(
        buildingBlockConfig,
        metricUserServiceFilters
      );
    }

    if (!widgetConfigDto?.dataDefinition?.metrics) {
      return {};
    }

    const { metrics } = widgetConfigDto.dataDefinition;
    const result: Record<
      string,
      {
        overTagAggFunction?: OverTagAggregators;
        overTimeAggFunction?: OverTimeAggregators;
      }
    > = {};

    Object.entries(metrics).forEach(([metricId, metricDefinition]) => {
      result[metricId] = {
        overTagAggFunction: metricDefinition?.overTagAggFunction || null,
        overTimeAggFunction: metricDefinition?.overTimeAggFunction || null
      };
    });

    return result;
  };

  /**
   * Updates the overTagAggFunction for all metrics in a BizDataQuery
   * @param bizDataQuery The BizDataQuery to update
   * @param overTagAggFunction The new overTagAggFunction value to set
   * @returns A new BizDataQuery with updated overTagAggFunction values
   */
  static updateOverTagAggFunction = (
    bizDataQuery: BizDataQuery,
    overTagAggFunction: OverTagAggregators
  ): BizDataQuery => {
    if (!bizDataQuery) {
      return bizDataQuery;
    }

    // Create a deep clone of the BizDataQuery
    const updatedBizDataQuery = cloneDeep(bizDataQuery);

    // Only update widgetConfig - buildingBlockConfig doesn't support overTagAggFunction
    if (updatedBizDataQuery.widgetConfig) {
      const widgetConfigDto = getDtoFromWidgetConfig(updatedBizDataQuery.widgetConfig);

      if (widgetConfigDto?.dataDefinition?.metrics) {
        const { metrics } = widgetConfigDto.dataDefinition;

        // Update all metrics with the new overTagAggFunction
        Object.values(metrics).forEach(metric => {
          metric.overTagAggFunction = overTagAggFunction;
        });

        // Convert back to WidgetConfig
        updatedBizDataQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);
      }
    }

    return updatedBizDataQuery;
  };

  /**
   * Updates the overTimeAggFunction for all metrics in a BizDataQuery
   * @param bizDataQuery The BizDataQuery to update
   * @param overTimeAggFunction The new overTimeAggFunction value to set
   * @returns A new BizDataQuery with updated overTimeAggFunction values
   */
  static updateOverTimeAggFunction = (
    bizDataQuery: BizDataQuery,
    overTimeAggFunction: OverTimeAggregators
  ): BizDataQuery => {
    if (!bizDataQuery) {
      return bizDataQuery;
    }

    // Create a deep clone of the BizDataQuery
    const updatedBizDataQuery = cloneDeep(bizDataQuery);

    // Only update widgetConfig - buildingBlockConfig doesn't support overTimeAggFunction
    if (updatedBizDataQuery.widgetConfig) {
      const widgetConfigDto = getDtoFromWidgetConfig(updatedBizDataQuery.widgetConfig);

      if (widgetConfigDto?.dataDefinition?.metrics) {
        const { metrics } = widgetConfigDto.dataDefinition;

        // Update all metrics with the new overTimeAggFunction
        Object.values(metrics).forEach(metric => {
          metric.overTimeAggFunction = overTimeAggFunction;
        });

        // Convert back to WidgetConfig
        updatedBizDataQuery.widgetConfig = getWidgetConfigFromDto(widgetConfigDto);
      }
    }

    return updatedBizDataQuery;
  };

  static getWidgetConfigDtoFromBuildingBlockConfig(
    buildingBlockConfig: BuildingBlockConfig,
    metricUserServiceFilters?: MetricUserServiceFilters
  ): WidgetConfigDTO {
    const { name, id, bizIdProps, buildingBlockDef } = buildingBlockConfig;
    const usrServiceFilters = Object.values(metricUserServiceFilters || {});
    const usrFilterExpressions: UserServiceFilterExpression[] = [];
    usrServiceFilters.forEach(e => {
      const { userServiceFilters } = e;
      if (userServiceFilters?.length) {
        userServiceFilters.forEach(e => {
          usrFilterExpressions.push(...e.userServiceFilterExpressions);
        });
      }
    });
    const bizEntityType = bizIdProps?.primary?.bizEntityTypeId;
    const userServiceEntityId = bizEntityType
      ? null
      : bizIdProps?.primary?.eventTypes?.userServiceInfo?.[0]?.userServiceEntityId;
    const { aggregator, fieldConfig, filters, sliceDef } = buildingBlockDef;
    if (filters?.filterExpressions?.length) {
      usrFilterExpressions.push(...filters.filterExpressions);
    }

    return {
      bizEntityType,
      userServiceEntityId,
      dataDefinition: {
        fields: {},
        metrics: {
          [id]: {
            id,
            name,
            sourceType: "userServiceField",
            userServiceFieldMetricConfig: {
              aggregator,
              eventFilters: {
                userServiceFilters: [
                  {
                    userServiceFilterExpressions: usrFilterExpressions
                  }
                ]
              },
              sliceSets: sliceDef?.sliceSets || [],
              userServiceField: fieldConfig?.userServiceField
            }
          }
        }
      },
      isStatic: false,
      name,
      visualizations: []
    };
  }

  static getBuildingBlockConfigFromWidgetConfig(widgetConfig: WidgetConfig, metricId: string): BuildingBlockConfig {
    const { name, dataDefinition } = widgetConfig;
    const { metrics } = dataDefinition;
    const metricValues = Object.values(metrics || {}).filter(m => m.sourceType === "userServiceField");
    const defaultMetric = metricValues[0] as UserServiceFieldMetricConfigDefinition;
    const metric = metrics[metricId];
    const metricConfig =
      metric?.sourceType === "userServiceField"
        ? metric?.userServiceFieldMetricConfig
        : defaultMetric?.userServiceFieldMetricConfig;
    const expressions =
      metricConfig?.filterExpressions ||
      metricConfig?.eventFilters?.userServiceFilters?.[0]?.userServiceFilterExpressions ||
      [];
    const sliceSets = metricConfig?.sliceSets || [];
    const userServiceField = metricConfig?.userServiceField;
    const aggregator = metricConfig?.aggregator || "avg";
    const metricName = metric?.name || "";

    return {
      id: metricId || generateId(),
      name,
      buildingBlockDef: {
        aggregator: aggregator,
        fieldConfig: {
          userServiceField
        },
        filters: {
          filterExpressions: expressions
        },
        sliceDef: {
          sliceSets: sliceSets
        },
        name: metricName
      }
    };
  }

  static getEntityTypeAndEventTypeFromIdProps = (idProps: BizIdProps) => {
    const { primary, secondary } = idProps || {};

    const { bizEntityTypeId, eventTypes } = primary || {};

    const { widgetId, cohortId } = secondary || {};

    return {
      entityTypeId: bizEntityTypeId,
      eventTypeId: eventTypes?.userServiceInfo?.[0]?.userServiceEntityId,
      widgetId,
      cohortId
    };
  };

  static getWidgetConfigClone(widgetConfig: WidgetConfigDTO) {
    const cloneWidgetConfig = cloneDeep(widgetConfig);

    const { metrics } = cloneWidgetConfig.dataDefinition;
    const metricUserServiceFilters = cloneWidgetConfig.metricUserServiceFilters || {};
    const oMetricIds = Object.keys(metrics);
    const nMetricIds = oMetricIds.map(() => generateId());

    const metricIdLookup = zipObject(oMetricIds, nMetricIds);
    oMetricIds.forEach(oMetricId => {
      const metric = metrics[oMetricId];
      const filters = metricUserServiceFilters[oMetricId];
      delete metrics[oMetricId];
      delete metricUserServiceFilters[oMetricId];

      const nMetricId = metricIdLookup[oMetricId];
      metric.id = nMetricId;

      if (metric.sourceType === "expression") {
        modifyMetricIdsForExpressionMetricConfig(metric.expressionMetricConfig, metricIdLookup);
      }

      metrics[nMetricId] = metric;
      metricUserServiceFilters[nMetricId] = filters;
    });

    const expressionString = widgetConfig?.labels?.expression;
    const newExpressionObject: Record<string, any> = {};
    if (expressionString && isJsonString(expressionString)) {
      const expressionObject = JSON.parse(expressionString);
      const oldExpressionKeys = Object.keys(expressionObject);
      oldExpressionKeys.forEach((x: string) => {
        const newKey = metricIdLookup[x];
        const oldExpressionObj = expressionObject[x];
        if (oldExpressionObj) {
          newExpressionObject[newKey] = oldExpressionObj;
          newExpressionObject[newKey].childMetricIds =
            oldExpressionObj?.childMetricIds?.map((metricId: string) => metricIdLookup[metricId]) || [];
        }
      });
    }

    cloneWidgetConfig.labels = {
      ...widgetConfig.labels,
      expression: JSON.stringify(newExpressionObject)
    };

    cloneWidgetConfig.visualizations.forEach(viz => {
      viz.dataDefs.forEach(def => {
        def.id = metricIdLookup[def.id] || def.id;
      });
    });

    return {
      widgetConfigDto: cloneWidgetConfig,
      metricIdLookup
    };
  }

  static getVisibleMetricIds(metrics: DataDefinition["metrics"]) {
    const visibleMetricIds: string[] = [];

    const metricIds = Object.keys(metrics);
    const extractedMetricIds: string[] = [];

    metricIds.forEach(metricId => {
      const metric = metrics[metricId];

      if (metric?.sourceType === "expression") {
        visibleMetricIds.push(metricId);

        const subMetricIds = getMetricIdsForExpressionMetric(metric.expressionMetricConfig);
        extractedMetricIds.push(...subMetricIds, metricId);
      }
    });

    metricIds.forEach(metricId => {
      if (!extractedMetricIds.includes(metricId)) {
        visibleMetricIds.push(metricId);
      }
    });

    return visibleMetricIds;
  }

  static updateMetricUserServiceFiltersForMetric(
    metricUserServiceFilters: MetricUserServiceFilters,
    metrics: Record<string, MetricDefinition>,
    metricId: string
  ) {
    const updatedMetricUserServiceFilters = pick(metricUserServiceFilters, [metricId]);
    const metric = metrics[metricId];

    if (metric.sourceType === "expression") {
      const relatedMetricIds = this.getMetricIdsForExpressionMetric(metric.expressionMetricConfig);
      relatedMetricIds.forEach(relatedMetricId => {
        updatedMetricUserServiceFilters[relatedMetricId] = metricUserServiceFilters[metricId];
      });
    }

    return updatedMetricUserServiceFilters;
  }

  static getWidgetTraceQueryLinks = (dataQueryConfig: Record<string, any>, widgetConfig: WidgetConfigDTO) => {
    if (dataQueryConfig?.aggregations) {
      const { aggregations } = dataQueryConfig;
      const entityTypeName = widgetConfig.bizEntityType;
      const usEntityName = widgetConfig?.userServiceEntityName;
      const filterValue = dataQueryConfig.filters?.value.replace(/\\/g, "");
      const queryObjs: Record<string, any> = {};
      const queryLinks: IncSelectOption[] = [];
      const constructLink = (obj: Record<string, any>) => qs.stringify(obj);

      const constructAggregationColumn = (
        aggregationObj: Record<string, any>,
        aggregation: string
      ): Record<string, any> => {
        const queryObj: Record<string, any> = {};
        const loopAggr = (obj: Record<string, any>, aggr: string) => {
          if (obj.fromField) {
            queryObj[aggr] = {
              column: obj.fromField
            };
          }
          if (obj.aggregations) {
            loopAggr(Object.values(obj.aggregations)[0], Object.keys(obj.aggregations)[0]);
          }
        };
        loopAggr(aggregationObj, aggregation);
        return queryObj;
      };

      const aggregationsKeys = Object.keys(aggregations);
      aggregationsKeys.forEach(aggr => {
        if (aggr === "i_entity") {
          queryObjs[entityTypeName] = {
            column: [aggregations[aggr].fromField]
          };
        } else if (aggr === "results") {
          queryObjs[usEntityName] = {
            column: [aggregations[aggr].field]
          };
        } else {
          const aggrQueryObj: Record<string, any> = constructAggregationColumn(aggregations[aggr], aggr);
          const queryKeys = [];
          const queryColumnValues = [];
          for (const [key, value] of Object.entries(aggrQueryObj)) {
            queryKeys.push(key === "i_entity" ? entityTypeName : key);
            queryColumnValues.push(value.column);
          }
          if (queryKeys.length) {
            queryObjs[queryKeys.join(", ")] = {
              column: queryColumnValues
            };
          }
        }
      });

      for (const [key, val] of Object.entries(queryObjs)) {
        queryLinks.push({
          label: key,
          value: constructLink({
            column: val.column,
            query: filterValue
          })
        });
      }
      return queryLinks;
    } else {
      return [];
    }
  };

  static getSelectorSpecFromTagFilters = (tagFilters: TagFilter[][]) => {
    const selectorSpec: SelectorSpec = {
      filters: []
    };

    tagFilters.forEach(tfArr => {
      const groups = groupBy(tfArr, tf => tf.fieldName);
      const tags: SelectorTag[] = [];

      forEach(groups, (tFs, fieldName) => {
        const tagValues = tFs.map(tf => tf.tagValue);
        tags.push({
          key: fieldName,
          value: tagValues
        });
      });

      selectorSpec.filters.push({
        tags
      });
    });

    return selectorSpec;
  };

  static getQuerySchemasByUSField = (
    querySchemas: WidgetQuerySchema[],
    usFieldWithMeta: UserServiceFieldWithMeta,
    userServiceId?: string,
    skipSummaryMetrics?: boolean,
    compareBEFieldName?: boolean
  ) => {
    const matchingQuerySchemas: WidgetQuerySchema[] = [];
    querySchemas.forEach(qs => {
      const { sourceUserServiceField } = qs;
      const { userServiceField } = usFieldWithMeta;
      let usFieldsMatching = false;
      if (sourceUserServiceField == null) {
        const { componentSourceFields } = qs;
        forEach(componentSourceFields, usf => {
          usFieldsMatching =
            usFieldsMatching || this.compareUSFields(usf, userServiceField, userServiceId, compareBEFieldName);
        });
      } else {
        usFieldsMatching = this.compareUSFields(
          sourceUserServiceField,
          userServiceField,
          userServiceId,
          compareBEFieldName
        );
      }
      const skipMetric = skipSummaryMetrics && isSystemCreatedMetric(qs);
      if (usFieldsMatching && !skipMetric) {
        matchingQuerySchemas.push(qs);
      }
    });

    return matchingQuerySchemas;
  };

  static getMetricIdsForExpressionMetric = (expressionMetricConfig: ExpressionMetricConfig) => {
    const metricIds = new Set<string>();
    this.extractMetricIdFromExpressionMetricConfig(expressionMetricConfig, metricIds, null);

    return Array.from(metricIds);
  };

  static getMetricNodesForExpressionMetric = (expressionMetricConfig: ExpressionMetricConfig) => {
    const sliceSpecs: SliceSpec[] = [];
    this.extractMetricIdFromExpressionMetricConfig(expressionMetricConfig, null, sliceSpecs);

    return sliceSpecs;
  };

  static extractMetricIdFromExpressionMetricConfig = (
    expressionMetricConfig: ExpressionMetricConfig,
    metricIdsSet: Set<string>,
    sliceSpecs: SliceSpec[]
  ): void => {
    const { expression, sliceSpec } = expressionMetricConfig;

    const metricId = sliceSpec?.metricId;
    if (metricId) {
      if (metricIdsSet) {
        metricIdsSet.add(metricId);
      }

      if (sliceSpecs) {
        sliceSpecs.push(sliceSpec);
      }
    }

    if (!expression) {
      return;
    }

    const { leftExpr, rightExpr } = expression;
    if (leftExpr?.expressionMetricConfig) {
      this.extractMetricIdFromExpressionMetricConfig(leftExpr.expressionMetricConfig, metricIdsSet, sliceSpecs);
    }

    if (rightExpr?.expressionMetricConfig) {
      this.extractMetricIdFromExpressionMetricConfig(rightExpr.expressionMetricConfig, metricIdsSet, sliceSpecs);
    }

    return;
  };

  static modifyMetricIdsForExpressionMetricConfig = (
    expressionMetricConfig: ExpressionMetricConfig,
    metricIdLookup: Record<string, string>
  ) => {
    const { expression, sliceSpec } = expressionMetricConfig;

    const metricId = sliceSpec?.metricId;
    if (metricId) {
      sliceSpec.metricId = metricIdLookup[metricId];
    }

    if (!expression) {
      return;
    }

    const { leftExpr, rightExpr } = expression;
    if (leftExpr?.expressionMetricConfig) {
      modifyMetricIdsForExpressionMetricConfig(leftExpr.expressionMetricConfig, metricIdLookup);
    }

    if (rightExpr?.expressionMetricConfig) {
      modifyMetricIdsForExpressionMetricConfig(rightExpr.expressionMetricConfig, metricIdLookup);
    }

    return;
  };

  static compareUSFields = (
    usFieldA: UserServiceField,
    usFieldB: UserServiceField,
    usId?: string,
    compareBEFieldName = false,
    omitPropsArray?: string[]
  ) => {
    const shouldCompareUS = !isEmpty(usId);
    let usMatches = true;

    if (shouldCompareUS) {
      const usFieldAHasUS = this.isUserServiceIncludedInUSField(usFieldA, usId);
      const usFieldBHasUS = this.isUserServiceIncludedInUSField(usFieldB, usId);
      usMatches = usFieldAHasUS && usFieldBHasUS;
    }

    if (shouldCompareUS && !usMatches) {
      return false;
    }

    const nOmitProps: string[] = [...omitProps, ...(omitPropsArray || [])];

    const omitUSFieldA = omit(usFieldA, nOmitProps);
    const omitUSFieldB = omit(usFieldB, nOmitProps);

    const usFieldsMatch = isEqual(omitUSFieldA, omitUSFieldB);

    if (usFieldsMatch && compareBEFieldName) {
      return usFieldA.bizEntityFieldName === usFieldB.bizEntityFieldName;
    }

    return usFieldsMatch;
  };

  static isUserServiceIncludedInUSField = (usField: UserServiceField, usId: string) => {
    const { userServices = [], allUserService = false } = usField || {};

    if (allUserService) {
      return true;
    }

    const matchingUsIdx = userServices.findIndex(({ userServiceEntityId }) => userServiceEntityId === usId);
    return matchingUsIdx !== -1;
  };

  private static getSliceSetFromMetricDefinition2(metricDef: MetricDefinition): SliceSet | UserServiceFieldSliceSet[] {
    const { usFieldMetricDef, usMetricMetricDef, bizEntityMetricDef, expressionMetricDef, entityMetricDef } =
      this.getMetricDefinitions(metricDef);
    if (usFieldMetricDef?.userServiceFieldMetricConfig) {
      return usFieldMetricDef.userServiceFieldMetricConfig.sliceSets;
    } else if (usMetricMetricDef.userServiceMetricConfig) {
      return usMetricMetricDef.userServiceMetricConfig.sliceSet;
    } else if (bizEntityMetricDef.bizEntityMetricConfig) {
      return bizEntityMetricDef.bizEntityMetricConfig.sliceSet;
    } else if (expressionMetricDef.expressionMetricConfig) {
      return { slices: [] };
    } else if (entityMetricDef.entityMetricConfig) {
      return { slices: [] };
    } else {
      return { slices: [] };
    }
  }
}

export const {
  getWidgetTraceQueryLinks,
  getSelectorSpecFromTagFilters,
  getQuerySchemasByUSField,
  getMetricIdsForExpressionMetric,
  getMetricNodesForExpressionMetric,
  extractMetricIdFromExpressionMetricConfig,
  modifyMetricIdsForExpressionMetricConfig,
  compareUSFields
} = WidgetConfigUtils;

/** @deprecated
 * Utility to return a compare Config or first Compare config to support old UI need to be deleted when old UI is no loneger supported
 * @param metricDef Metric Def object can be null
 * @param compareConfigId
 */

export function getOpdCompareConfig<MD extends MetricConfigDefinition>(
  metricDef: MD,
  compareConfigId?: string
): CompareConfig {
  const compareConfigs = metricDef?.operationalizeConfig?.compareConfigs;
  let selectedCC: CompareConfig = null;
  if (!isEmpty(compareConfigs)) {
    if (compareConfigId) {
      selectedCC = compareConfigs[compareConfigId];
    }
    selectedCC = first(Object.values(compareConfigs));
  }
  return selectedCC;
}

const omitProps: string[] = ["allUserService", "userServices", "bizEntityFieldName", "displayBizEntityFieldName"];

const getEntityIdsFromFilterExpressions = (filtersExpressions: UserServiceFilterExpression[], entityIds: string[]) => {
  filtersExpressions.forEach(f => {
    const dataType = f.field.entityField?.propType !== "NA" ? f.field.entityField?.propType : f.field.dataType;
    if (dataType === "ENTITY") {
      if (f.operator === "in") {
        entityIds.push(...(f.values || []));
      } else {
        entityIds.push(f.value);
      }
    }
  });
};

const getEntityIdsFromTagFilters = (tagFilters: TagFilter[]) => {
  const entityIds: string[] = [];
  tagFilters.forEach(tagFilter => {
    const { tagValue, tagValues } = tagFilter;
    const fTagValues = tagValues || [tagValue];
    entityIds.push(...(fTagValues || []));
  });
  return entityIds.filter(value => isEntity(value));
};

const getEntityIdsFromExpressionTree = (expressionTree: UserServiceFilterExpressionTree, entityIds: string[]) => {
  if (expressionTree) {
    const { filterNodes } = expressionTree;
    filterNodes?.forEach(filterNode => {
      const { expression, expressionTree } = filterNode;

      if (expression) {
        getEntityIdsFromFilterExpressions([expression], entityIds);
      }

      if (expressionTree) {
        getEntityIdsFromExpressionTree(expressionTree, entityIds);
      }
    });
  }

  return;
};

const constructMSR = (
  metric: MetricDefinition,
  querySchema: WidgetQuerySchema,
  usfSlices?: UserServiceFieldSliceSet,
  compareConfigs?: CompareConfig[],
  sliceSet?: SliceSet
): MetricAndSliceRecord => ({
  metricId: metric.id,
  metricSourceType: metric.sourceType,
  metricDefinition: metric,
  querySchema,
  usfSlices: usfSlices,
  sliceSet: querySchema?.sliceSet || sliceSet,
  isOperationalized: !isEmpty(compareConfigs),
  compareConfigs: compareConfigs || []
});
