import { DataFrame, readCSV } from "danfojs";
import { ArrayType1D, ArrayType2D } from "danfojs/dist/danfojs-base/shared/types";
import { logger } from "../../core";

export type CSVFilterConditionType =
  | "contains"
  | "eq"
  | "gt"
  | "in"
  | "nin"
  | "lt"
  | "array_contains"
  | "json_path_match"
  | "neq"
  | "gte"
  | "lte"
  | "starts_with"
  | "ends_with";

export type CSVFilter = {
  id: string;
  column: CsvColumn;
  isJsonFilter: boolean;
  condition: CSVFilterConditionType;
  values: string[];
  matchAny: boolean;
  jsonPath: string;
};

export type CSVReducerType = "count" | "list" | "most_frequent" | "custom";
export type CSVFormulaMode = "js" | "excel";
export type CSVCellType = ArrayType1D | ArrayType2D;
export class CsvReader {
  readonly readCsv = async (csvString: string | any[], delimiter?: string, useFile = false): Promise<CsvFrame> => {
    if (Array.isArray(csvString)) {
      const df = new DataFrame(csvString);
      return df;
    }
    let url = csvString;
    if (!useFile) {
      const blob = new Blob([csvString], { type: "text/csv" });

      // Create an Object URL
      url = URL.createObjectURL(blob);
    }
    try {
      const dt = await readCSV(url, {
        header: true,
        delimiter,
        dynamicTyping: true
      });
      return dt;
    } catch (error) {
      logger.error("CSV Reader", "Error fetching csv", error);
    }
    return null;
  };
}

export type CSVJsonReducer = {
  displayType: CSVReducerType;
  jsonPath: string;
  // js function to be invoke
  customReducer: string;
  // excel function to be invoke
  excelFormula: string;
  formulaMode: CSVFormulaMode;
};

export type CsvColumn = {
  id: string;
  csvColIndex: number;
  label: string;
  visible: boolean;
  type: "json_array" | "string" | "number" | "boolean" | "other" | "list" | "object";
};

export type NestedCsvColumn = Record<string, string[]>;

export type CsvFrame = DataFrame;
