import { defaults } from "lodash";
import { useCallback, useState } from "react";

interface DataStates<R, E> {
  data: R | null;
  error: E | null;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export interface FetchDataStatus<R, E> extends DataStates<R, E> {
  setStatus: (data: R | null, isFetching: boolean, isSuccess: boolean, isError: boolean, error: E | null) => void;
  resetStates: () => void;
}

export const useFetchDataStatus = <R, E = unknown>(initialState?: Partial<DataStates<R, E>>): FetchDataStatus<R, E> => {
  const [dataStates, setDataStates] = useState<DataStates<R, E>>(
    defaults(initialState, {
      data: null,
      error: null,
      isFetching: false,
      isError: false,
      isSuccess: false
    })
  );

  const setStatus = useCallback(
    (data: R | null, isFetching: boolean, isSuccess: boolean, isError: boolean, error: E | null) => {
      setDataStates({
        data,
        error,
        isError,
        isSuccess,
        isFetching
      });
    },
    []
  );

  const resetStates = useCallback(() => {
    setStatus(null, false, false, false, null);
  }, [setStatus]);

  return {
    ...dataStates,
    setStatus,
    resetStates
  };
};
